import React, { useEffect } from 'react'
import { isArray } from 'lodash'
import { OPTION_TYPE } from 'config'
import { findQuestionsToShowInReview, isImgLink } from 'utils/helper'
import { AppFC, IReviewAssessmentResponses } from 'types'
import { createMarkup } from '../AssessmentQuestion/utils'
import { UserFile } from '__generated__/api-types-and-hooks'
import Loader from 'components/Loaders'
import { useUserAssessmentResponse } from 'hooks/useUserAssessmentResponse'

export const ReviewAssessmentResponses: AppFC<IReviewAssessmentResponses> = ({
  userId,
  userName,
  businessName,
  assessmentType,
  resetAssessment,
  setCompletedAssessmentAt,
}) => {
  const { userAssessmentResponse, isLoading } = useUserAssessmentResponse(assessmentType, userId)
  let assessmentResponses = userAssessmentResponse ? userAssessmentResponse.assessmentResponse : []
  useEffect(() => {
    resetAssessment()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (setCompletedAssessmentAt && userAssessmentResponse)
      setCompletedAssessmentAt(userAssessmentResponse.completedAt)
    // eslint-disable-next-line
  }, [userAssessmentResponse])

  return (
    <>
      {isLoading ? (
        <Loader loader="ReviewAssessmentLoader" />
      ) : (
        <>
          {findQuestionsToShowInReview(assessmentResponses)?.map((item, index) => {
            const userFile =
              item.optionType === OPTION_TYPE.UPLOAD ? (item?.label as UserFile) : null

            return (
              <div
                className={`pb-10 ${
                  index === findQuestionsToShowInReview(assessmentResponses)?.length - 1
                    ? 'border-primary-outline border-b-2 mb-10'
                    : ''
                }`}
                key={index}
              >
                <div
                  className="font-bold [&_span]:text-black pb-2"
                  dangerouslySetInnerHTML={createMarkup(item.questionLabel, userName, businessName)}
                />

                {item.optionType === OPTION_TYPE.UPLOAD ? (
                  <>
                    {userFile?.url && isImgLink(userFile?.url!) ? (
                      <img src={userFile.url!} alt="Logo" className="max-w-[200px] max-h-[200px]" />
                    ) : (
                      <div className="!text-grey-darkest1x font-semibold">
                        {userFile?.name ? userFile?.name : 'skipped'}
                      </div>
                    )}
                  </>
                ) : item.optionType === OPTION_TYPE.MULTI_FIELD && isArray(item.label) ? (
                  <div>
                    {item.label.map((multiFieldItem, multiFieldIndex) => (
                      <div key={multiFieldIndex} className="mb-2 pt-1">
                        <div className="!text-grey-darkest1x font-semibold">
                          {`${multiFieldItem.label}: `}
                          {multiFieldItem.value}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="!text-grey-darkest1x font-semibold">
                    {isArray(item.label) ? (
                      item.label.map((labelItem, labelIndex) => (
                        <div className="!text-grey-darkest1x font-semibold pb-2" key={labelIndex}>
                          {labelItem}
                        </div>
                      ))
                    ) : (
                      <div className="!text-grey-darkest1x font-semibold">
                        {item.optionType === OPTION_TYPE.CURRENCY
                          ? typeof item.label === 'string'
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              }).format(parseFloat(item.label.replace(/[^\d.]/g, '')))
                            : 'skipped'
                          : item.label || 'skipped'}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
ReviewAssessmentResponses.defaultProps = {
  setCompletedAssessmentAt: () => {},
}
