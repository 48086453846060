import React, { useEffect, useState } from 'react'
import map from 'lodash/map'
import UnlockedIcon from 'components/Common/SvgIcons/UnlockedIcon'
import LockedIcon from 'components/Common/SvgIcons/LockedIcon'
import TickCircleIcon from 'components/Common/SvgIcons/TickCircleIcon'
import AssessmentAccordion from 'components/Clients/Client/ClientPlaybook/AssessmentAccordion'
import { ampli } from 'ampli'
import { ASSESSMENTS } from 'config'
import Loader from 'components/Loaders'
import { ReviewAssessmentResponsesContainer } from 'pages/Assessment/containers'
import { isEmpty } from 'lodash'

const Assessments = ({
  userId,
  businessName,
  assessmentList,
  clientAssessmentLoading,
  getAssessmentListAction,
}) => {
  const [activeAssessment, setActiveAssessment] = useState('')

  useEffect(() => {
    if (!isEmpty(userId)) {
      getAssessmentListAction({ userId })
    }
  }, [getAssessmentListAction, userId])

  const handleOpenAssessments = (assessment: any) => {
    const type = assessment.type
    ampli.assessmentReviewed({
      id: type,
      name: ASSESSMENTS[assessment.type]?.title,
      userId: userId,
    })
    setActiveAssessment(type)
  }

  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:col-span-6">
      <div className="font-normal text-2xl text-zinc font-primary mb-6">Assessments</div>
      <div className="flex flex-col justify-center items-center">
        {clientAssessmentLoading ? (
          <Loader loader={'AdvisorPlayBookAndTools'} />
        ) : (
          map(assessmentList, (assessment, key) => (
            <AssessmentAccordion
              key={key}
              open={activeAssessment === assessment.type}
              title={assessment.title}
              icon={
                assessment.isCompleted ? (
                  <TickCircleIcon />
                ) : assessment.isLocked ? (
                  <LockedIcon />
                ) : (
                  <UnlockedIcon />
                )
              }
              outlined={!assessment.isCompleted}
              isLocked={assessment.isLocked}
              onOpen={() => handleOpenAssessments(assessment)}
            >
              {activeAssessment === assessment.type && (
                <>
                  <ReviewAssessmentResponsesContainer
                    userId={userId}
                    businessName={businessName}
                    assessmentType={assessment?.type}
                  />
                </>
              )}
            </AssessmentAccordion>
          ))
        )}
      </div>
    </div>
  )
}

export default Assessments
