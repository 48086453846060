import React, { FC, useMemo } from 'react'

import { ContentBoxWrapper, LabelWrapper, BubbleWrapper } from './styles'
import CheckIcon from 'components/Common/SvgIcons/CheckIcon'

export enum RecommendableStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}

interface IRecommendationProgressBubbleProps {
  status?: RecommendableStatus
}

const statusLabel = {
  [RecommendableStatus.NOT_STARTED]: 'Not Started',
  [RecommendableStatus.IN_PROGRESS]: 'In Progress',
  [RecommendableStatus.COMPLETED]: 'Completed',
}

const RecommendationProgressBubble: FC<IRecommendationProgressBubbleProps> = ({ status }) => {
  const label = useMemo(() => {
    return status && statusLabel[status]
  }, [status])

  return (
    <ContentBoxWrapper className="animate-fade-in">
      <LabelWrapper status={status}>{label}</LabelWrapper>
      <BubbleWrapper status={status}>
        {status === RecommendableStatus.COMPLETED && <CheckIcon className="fill-white" />}
      </BubbleWrapper>
    </ContentBoxWrapper>
  )
}

export default RecommendationProgressBubble
