import React, { FC } from 'react'
import { LABEL, USER_MANAGEMENT_TABS } from 'config'

import ModalBox from 'components/Common/ModalBox'
import { UserManagementModalBoxWrapper } from 'components/Admin/UserManagement/UserManagementList/style'

import { EditAccountContainer } from 'pages/Admin/container/UserManagement'
import ResetPasswordConfirmationModalContainer from 'pages/Common/container'
import { IUser } from 'components/Admin/UserManagement/UserManagementList'
import { useFlashParams } from 'hooks/useFlashParams'

interface IEditAccountDetailsProps {
  selectedUser: IUser
  isSelectedTab: string
  openResetPasswordModal: boolean
  openUserManagementModal: boolean
  getBSOProfileAction: () => void
  setOpenUserManagementModal: (value: boolean) => void
  setOpenResetPasswordModal: (value: boolean) => void
}

const EditAccountDetails: FC<IEditAccountDetailsProps> = ({
  selectedUser,
  isSelectedTab,
  getBSOProfileAction,
  openResetPasswordModal,
  openUserManagementModal,
  setOpenResetPasswordModal,
  setOpenUserManagementModal,
}) => {
  const { removeFlashParams } = useFlashParams<{ validationErrorMessage?: string }>()

  const handleClose = () => {
    getBSOProfileAction()
    setOpenUserManagementModal(false)
    removeFlashParams(['validationErrorMessage'])
  }

  return (
    <>
      {openUserManagementModal && (
        <UserManagementModalBoxWrapper>
          <ModalBox
            height="h-full"
            title={LABEL.EDIT_ACCOUNT}
            width="xs:w-full sm:w-auto rounded-[10px]"
            onClose={() => handleClose()}
          >
            <EditAccountContainer
              selectedUser={selectedUser}
              userId={selectedUser?.userId}
              isBusiness={isSelectedTab === USER_MANAGEMENT_TABS.LINK_BUSINESSES}
              handleClose={() => handleClose()}
            />
          </ModalBox>
        </UserManagementModalBoxWrapper>
      )}

      {openResetPasswordModal && (
        <ResetPasswordConfirmationModalContainer
          email={selectedUser?.email || ''}
          onClose={() => setOpenResetPasswordModal(false)}
        />
      )}
    </>
  )
}

export default EditAccountDetails
