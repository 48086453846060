import {
  GET_USER_CONNECTORS,
  GET_USER_CALENDAR_EVENTS,
  GET_OAUTH_URL,
  RESET_OAUTH_URL,
  GET_CALENDLY_USER,
  GET_BO_ADVISOR,
  GET_USER_MEETING,
  SUBSCRIBE_CALENDLY_WEBHOOK,
  HANDLE_CYCLR_OAUTH,
  UPDATE_CALENDAR_MEETING,
  DELETE_GOOGLE_CALENDAR_EVENT,
  UPDATE_GOOGLE_CALENDAR_EVENT,
  SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK,
  CANCEL_OUTLOOK_MEETING,
  GET_MEETING_TYPES,
  SET_USER_AVAILABILITY,
  GET_USER_AVAILABILITY,
  SET_IS_SCHEDULED,
  SET_USER_MEETING,
  OPEN_SLOTS_MODAL,
  UPDATE_MEETING_STEPPER,
  UPDATE_USER_CALENDAR_EVENT,
  CANCEL_USER_CALENDAR_EVENT,
  GET_USER_AVAILABILITY_HOURS,
  UPDATE_USER_AVAILABILITY_HOURS,
  GET_ADVISOR_PROFILE,
  GET_USER_MEETING_BY_EVENT_ID,
  OPEN_MEETING_CONFIRMATION,
} from 'store/types'

interface IOpenMeetingConfirmationModal {
  error: string
  stepType: string
  isMeetingDeletion?: boolean
}
export const getUserConnectorsAction = {
  STARTED: (payload) => ({
    type: GET_USER_CONNECTORS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_USER_CONNECTORS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_USER_CONNECTORS.REJECTED, payload: error }),
}

export const getUserCalendarEventsAction = {
  STARTED: (payload) => ({
    type: GET_USER_CALENDAR_EVENTS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_USER_CALENDAR_EVENTS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_USER_CALENDAR_EVENTS.REJECTED, payload: error }),
  RESET: () => ({ type: GET_USER_CALENDAR_EVENTS.RESET }),
}

export const getOAuthUrlAction = {
  STARTED: (payload) => ({
    type: GET_OAUTH_URL.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_OAUTH_URL.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_OAUTH_URL.REJECTED, payload: error }),
}

export const getCalendlyUserAction = {
  STARTED: (payload) => ({
    type: GET_CALENDLY_USER.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_CALENDLY_USER.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_CALENDLY_USER.REJECTED, payload: error }),
}

export const resetOAuthUrlAction = () => ({ type: RESET_OAUTH_URL })

export const getBoAdvisorAction = {
  STARTED: (payload) => ({ type: GET_BO_ADVISOR.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_BO_ADVISOR.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_BO_ADVISOR.REJECTED, payload }),
}

export const subscribeCalendlyWebhookAction = {
  STARTED: (payload) => ({ type: SUBSCRIBE_CALENDLY_WEBHOOK.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: SUBSCRIBE_CALENDLY_WEBHOOK.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: SUBSCRIBE_CALENDLY_WEBHOOK.REJECTED, payload }),
}
export const subscribeGoogleCalendarWebhookAction = {
  STARTED: (payload) => ({ type: SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.REJECTED, payload }),
}

export const getUserMeetingAction = {
  STARTED: (payload: any) => ({ type: GET_USER_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_USER_MEETING.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_USER_MEETING.REJECTED, payload }),
}

export const handleCyclrOAuthAction = {
  STARTED: (payload: any) => ({ type: HANDLE_CYCLR_OAUTH.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: HANDLE_CYCLR_OAUTH.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: HANDLE_CYCLR_OAUTH.REJECTED, payload }),
}

export const updateCalendarMeetingAction = {
  STARTED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.REJECTED, payload }),
}
export const updateTackleMeetingAction = {
  STARTED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.REJECTED, payload }),
}

export const deleteGoogleCalendarEventAction = {
  STARTED: (payload: any) => ({ type: DELETE_GOOGLE_CALENDAR_EVENT.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: DELETE_GOOGLE_CALENDAR_EVENT.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: DELETE_GOOGLE_CALENDAR_EVENT.REJECTED, payload }),
}
export const updateGoogleCalendarEventAction = {
  STARTED: (payload: any) => ({ type: UPDATE_GOOGLE_CALENDAR_EVENT.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_GOOGLE_CALENDAR_EVENT.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: UPDATE_GOOGLE_CALENDAR_EVENT.REJECTED, payload }),
}

export const cancelOutLookMeetingAction = {
  STARTED: (payload: any) => ({ type: CANCEL_OUTLOOK_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: CANCEL_OUTLOOK_MEETING.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: CANCEL_OUTLOOK_MEETING.REJECTED }),
}

export const getMeetingTypesAction = {
  STARTED: (payload: any) => ({ type: GET_MEETING_TYPES.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_MEETING_TYPES.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: GET_MEETING_TYPES.REJECTED, error }),
}

export const setUserAvailabilityAction = {
  STARTED: (payload: any) => ({ type: SET_USER_AVAILABILITY.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: SET_USER_AVAILABILITY.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: SET_USER_AVAILABILITY.REJECTED, error }),
}

export const getUserAvailabilityAction = {
  STARTED: (payload: any) => ({ type: GET_USER_AVAILABILITY.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_USER_AVAILABILITY.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: GET_USER_AVAILABILITY.REJECTED, error }),
}
export const getUserAvailabilityHoursAction = {
  STARTED: (payload: any) => ({ type: GET_USER_AVAILABILITY_HOURS.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_USER_AVAILABILITY_HOURS.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: GET_USER_AVAILABILITY_HOURS.REJECTED, error }),
}
export const updateUserAvailabilityHoursAction = {
  STARTED: (payload: any) => ({ type: UPDATE_USER_AVAILABILITY_HOURS.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: UPDATE_USER_AVAILABILITY_HOURS.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: UPDATE_USER_AVAILABILITY_HOURS.REJECTED, error }),
}
export const setUserMeetingAction = {
  STARTED: (payload: any) => ({ type: SET_USER_MEETING.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: SET_USER_MEETING.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: SET_USER_MEETING.REJECTED, error }),
}

export const openSlotsModalAction = (payload) => ({
  type: OPEN_SLOTS_MODAL,
  payload,
})
export const updateMeetingStepperAction = (payload) => ({
  type: UPDATE_MEETING_STEPPER,
  payload,
})

export const updateUserCalendarEventAction = {
  STARTED: (payload: any) => ({ type: UPDATE_USER_CALENDAR_EVENT.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: UPDATE_USER_CALENDAR_EVENT.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: UPDATE_USER_CALENDAR_EVENT.REJECTED }),
}

export const cancelUserCalendarEventAction = {
  STARTED: (payload: any) => ({ type: CANCEL_USER_CALENDAR_EVENT.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: CANCEL_USER_CALENDAR_EVENT.FULLFILLED,
    payload,
  }),
  REJECTED: (payload) => ({ type: CANCEL_USER_CALENDAR_EVENT.REJECTED, payload }),
}

export const setIsScheduledAction = (payload: boolean) => ({
  type: SET_IS_SCHEDULED,
  payload,
})

export const getAdvisorProfileAction = {
  STARTED: (payload) => ({
    type: GET_ADVISOR_PROFILE.STARTED,
    payload,
  }),
  FULLFILLED: (user) => ({ type: GET_ADVISOR_PROFILE.FULLFILLED, payload: user }),
  REJECTED: () => ({
    type: GET_ADVISOR_PROFILE.REJECTED,
  }),
}

export const getUserMeetingByEventIdAction = {
  STARTED: (payload: { meetingId: string }) => ({
    type: GET_USER_MEETING_BY_EVENT_ID.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({
    type: GET_USER_MEETING_BY_EVENT_ID.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_USER_MEETING_BY_EVENT_ID.REJECTED }),
}

export const openMeetingConfirmationModalBoxAction = (payload: IOpenMeetingConfirmationModal) => ({
  type: OPEN_MEETING_CONFIRMATION,
  payload,
})
