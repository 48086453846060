import React from 'react'
import CrossIcon from 'assets/svgs/cross.svg'
import { AppFC } from 'types'

const CloseButton: AppFC = ({ onClick, className }) => {
  return (
    <div className={className} onClick={onClick}>
      <img
        src={CrossIcon}
        alt="cross-icon"
        className="cursor-pointer border-solid border-1 p-2  rounded-full border border-primary"
      />
    </div>
  )
}

CloseButton.defaultProps = {
  className: '',
  onClick: () => {},
}

export default CloseButton
