import React, { FC, useEffect } from 'react'
import map from 'lodash/map'
import upperCase from 'lodash/upperCase'
import ToolItemStatus from 'components/Clients/Client/ClientPlaybook/ToolItemStatus'
import Loader from 'components/Loaders'
import { isEmpty } from 'lodash'

interface ITool {
  isConnected: boolean
  isRecommended: boolean
  recommendedBy: string
  name: string
  group: string
  toolUrl: string
  imageUrl: string
}

interface IConnectedTool {
  [key: string]: ITool[]
}

interface ITools {
  clientId: string
  connectedTools: IConnectedTool
  clientConnectedToolsLoading: boolean
  getConnectedToolsAction: () => void
}
const Tools: FC<ITools> = ({
  clientId,
  connectedTools,
  clientConnectedToolsLoading,
  getConnectedToolsAction,
}) => {
  useEffect(() => {
    if (!isEmpty(clientId)) {
      getConnectedToolsAction()
    }
  }, [getConnectedToolsAction, clientId])

  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
      <div className="font-normal text-2xl text-zinc font-primary pb-3 mb-3 border-primary-outline border-0 border-b">
        Connected Tools
      </div>
      {clientConnectedToolsLoading ? (
        <Loader loader={'advisorConnectedTool'} />
      ) : (
        map(connectedTools, (values, key) => (
          <div key={key} className="mb-6">
            <h3 className="text-sm text-zinc mb-3 font-primary">{upperCase(key)}</h3>
            {map(values, (item, index) => (
              <ToolItemStatus key={index} data={item} />
            ))}
          </div>
        ))
      )}
    </div>
  )
}

export default Tools
