import { useMemo } from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { RecommendableStatus } from 'components/Owner/PlayBooksCategoryDetails/RecommendationProgressBubble'

export const ContentBoxWrapper = styled.div`
  ${tw`flex gap-1.5 sm:gap-2 items-center`}
`

export const LabelWrapper = styled.p<{ status?: RecommendableStatus }>(({ status }) => {
  return [
    tw`text-xs sm:text-sm font-bold`,
    status === RecommendableStatus.NOT_STARTED ? tw`text-grey-darkest` : tw`text-green`,
  ]
})

export const BubbleWrapper = styled.div<{ status?: RecommendableStatus }>(({ status = '' }) => {
  const bubbleClasses = useMemo(() => {
    return {
      [RecommendableStatus.NOT_STARTED]: tw`bg-grey-darkest`,
      [RecommendableStatus.IN_PROGRESS]: tw`bg-white border-[2px] border-green`,
      [RecommendableStatus.COMPLETED]: tw`bg-green`,
    }[status]
  }, [status])

  return [
    tw`flex items-center justify-center rounded-full h-3 w-3 sm:h-4 sm:w-4 p-1`,
    bubbleClasses,
  ]
})
