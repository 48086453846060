import React from 'react'
import InfoCircleIcon from 'components/Common/SvgIcons/InfoCircleIcon'

const ToolItemStatus = ({ data }) => {
  const hasError = !data.isConnected && data.isRecommended

  const getItemStatus = () => {
    let status: any = []
    if (data.isRecommended) status.push('recommended')
    status.push(data.isConnected ? 'connected' : 'not connected')
    return status.join(', ')
  }

  return (
    <div
      className={`mb-3 pl-8 text-lg relative ${
        hasError ? 'text-primary-error' : 'text-black-light'
      }`}
    >
      {hasError && (
        <span className="absolute left-0">
          <InfoCircleIcon className="" />
        </span>
      )}
      <span className="font-primary">
        <span className="mr-2">{data.name}</span>
        <span className="italic text-sm">({getItemStatus()})</span>
      </span>
    </div>
  )
}

export default ToolItemStatus
