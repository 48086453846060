import React, { useState, useEffect, FC } from 'react'
import draftToHtml from 'draftjs-to-html'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'

import { MeetingDetailsHeader } from 'components/Calendar/Meetings/style'
import ClockIcon from 'components/Common/SvgIcons/ClockIcon'
import CalendarIcon from 'components/Common/SvgIcons/CalendarIcon'
import { Divider, IconWithLabel } from 'components/Calendar/style'

import hookForms from 'utils/hookForms'
import { CALENDAR, FORM, MEETING_STEPPER } from 'config'
import moment from 'moment-timezone'
import { isValidEmailAddress } from '../../../utils/strings'
import MeetingDetails from './MeetingDetails'
import { EditMeetingModal } from './EditMeetingModal'
import { ampli } from '../../../ampli'
import { getRoleFromPath } from '../../../utils/helper'
import { ExternalMeetingStatus } from './MeetingDetails/ExternalMeetingStatus'
import MeetingConfirmation from './MeetingConfirmation'
import { UserProfile } from '__generated__/api-types-and-hooks'
import { UserBasicInfo, extendedCalendarEvents } from 'types'
import ModalBox from 'components/Common/ModalBox'
import MeetingUpdatedModalBox from './MeetingUpdatedModalBox'
import MeetingCancelIcon from 'components/Common/SvgIcons/MeetingCancelIcon'

interface ICalendarEventEditModalProps {
  user: UserProfile
  event: extendedCalendarEvents
  timeMin?: string
  timeMax?: string
  closeModal: () => void
  meetingType?: string
  showEditButton: boolean
  setShowEditButton: (param: boolean) => void
  updateMeetingAction: (params) => void
  cancelUserCalendarEventAction: (params) => void
  openMeetingConfirmationModalBoxAction: (input: { error: string; stepType: string }) => void
  stepType: string
  isMeetingDeletion: boolean
  errorMessage: string
  getTackleMeetingEventsAction?: (input: { filter: string; ownerId: string }) => void
  isDisabled: boolean
}

export const CalendarEventEditModal: FC<ICalendarEventEditModalProps> = ({
  user,
  event,
  timeMin,
  timeMax,
  closeModal,
  meetingType,
  showEditButton,
  setShowEditButton,
  updateMeetingAction,
  cancelUserCalendarEventAction,
  openMeetingConfirmationModalBoxAction,
  stepType,
  isMeetingDeletion = false,
  errorMessage = '',
  getTackleMeetingEventsAction,
  isDisabled,
}) => {
  const [description, setDescription] = useState(EditorState.createEmpty())

  useEffect(() => {
    setDescription(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(event?.description || '<p></p>').contentBlocks
        )
      )
    )
    // eslint-disable-next-line
  }, [event])
  const filter = meetingType?.trim()

  const onSubmit = () => {
    const form = hookForms.getForm(FORM.MEETING_SCHEDULED)
    const meetingForm = form?.getValues()
    let attendeesArray: string[] = meetingForm?.attendees?.split(',')
    let attendeesEmail: string[] = []
    if (attendeesArray?.length) {
      attendeesArray.forEach((attendee) => {
        if (isValidEmailAddress(attendee)) {
          attendeesEmail.push(attendee.trim())
        }
        return attendeesEmail
      })
    }

    let descriptionAndDetail =
      convertToRaw(description.getCurrentContent()).blocks[0].text.length > 0
        ? draftToHtml(convertToRaw(description.getCurrentContent()))
        : ''

    const updateCalendarMeetingPayload = {
      title: meetingForm.meetingTitle,
      eventId: event?.eventId,
      tackleId: event.tackleId,
      location: meetingForm.meetingLocation,
      eventType: event?.eventType,
      attendees: attendeesEmail,
      description: descriptionAndDetail,
      videoLinkDescription: meetingForm.videoLinkDescription,
    }
    const getCalendarEventPayload = {
      timeMin,
      timeMax,
      calendarName: '',
    }
    updateMeetingAction({
      filter,
      getCalendarEventPayload,
      updateCalendarMeetingPayload,
      ownerId: event?.ownerId,
    })
  }
  const cancelEventHandler = () => {
    let deleteEventPayload = {
      tackleId: event?.tackleId,
      eventId: event?.eventId,
      eventType: event?.eventType,
    }
    let getEventPayload = {
      calendarName: '',
      timeMin,
      timeMax,
    }
    let getTackleMeetingPayload = {
      filter: meetingType,
      ownerId: event?.inviteeId,
    }

    cancelUserCalendarEventAction({
      deleteEventPayload,
      getTackleMeetingPayload,
      getEventPayload,
    })
  }

  const cancelMeeting = () => {
    let startTime = moment(event.start as Date, 'hh:mm')
    let endTime = moment(event.end as Date, 'hh:mm')
    const userRole = getRoleFromPath()
    cancelEventHandler()
    ampli.meetingCanceled({
      initiatorRole: userRole,
      initiatorName:
        user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : '',
      meetingTitle: event?.title,
      originatorName: event?.organizerName || '',
      duration: endTime.diff(startTime, 'minutes').toString(),
    })
    setShowEditButton(false)
  }

  const deleteMeetingConfirmation = () => {
    openMeetingConfirmationModalBoxAction({
      error: '',
      stepType: MEETING_STEPPER.MEETING_WARNING,
    })
  }

  const participant: UserBasicInfo = {
    firstName:
      user.firstName?.trim().toLowerCase() ===
      event?.organizerDetail?.firstName?.trim().toLowerCase()
        ? event?.participantDetail?.firstName
        : event?.organizerDetail?.firstName,
    lastName:
      user.lastName?.trim().toLowerCase() === event?.organizerDetail?.lastName?.trim().toLowerCase()
        ? event?.participantDetail?.lastName
        : event?.organizerDetail?.lastName,
  }

  const handleCancel = () => {
    openMeetingConfirmationModalBoxAction({
      error: '',
      stepType: '',
    })
  }

  const handleClose = () => {
    handleCancel()
    if (stepType === MEETING_STEPPER.MEETING_CONFIRMATION) {
      closeModal()
    }
  }

  const handleConfirmationCloseModal = () => {
    if (filter && getTackleMeetingEventsAction) {
      getTackleMeetingEventsAction({ filter, ownerId: event?.inviteeId || '' })
    }
    closeModal()
  }

  return (
    <>
      <ModalBox
        height={'xs:h-full sm:h-auto max-h-full'}
        Icon={MeetingCancelIcon}
        margin="sm:mx-5"
        onClose={handleClose}
      >
        {stepType === MEETING_STEPPER.MEETING_CONFIRMATION && (
          <MeetingUpdatedModalBox
            advisor={participant}
            errorMessage={errorMessage}
            isMeetingDeletion={isMeetingDeletion}
            modalHandler={handleConfirmationCloseModal}
            openMeetingConfirmationModalBoxAction={openMeetingConfirmationModalBoxAction}
          />
        )}

        {stepType === MEETING_STEPPER.MEETING_WARNING && (
          <MeetingConfirmation
            errorMessage=""
            successMessage=""
            isWarning={true}
            handleClose={cancelMeeting}
            handleCancel={handleCancel}
            isLoading={isDisabled}
            Heading={'Are you sure you want to delete?'}
          />
        )}
      </ModalBox>

      {stepType !== MEETING_STEPPER.MEETING_WARNING &&
        stepType !== MEETING_STEPPER.MEETING_CONFIRMATION && (
          <EditMeetingModal
            onClose={closeModal}
            onEdit={() => setShowEditButton(false)}
            onCancel={deleteMeetingConfirmation}
            title={event?.title}
            editMode={!showEditButton}
            eventType={event.eventType as CALENDAR}
            stepType={stepType}
          >
            <>
              <MeetingDetailsHeader>
                <IconWithLabel>
                  <CalendarIcon className={'inline mr-3 fill-primary-text'} />
                  {moment(event.start as Date).format('dddd, MMMM Do, YYYY')}
                </IconWithLabel>
                <IconWithLabel>
                  <ClockIcon
                    className={
                      'mb-auto inline mr-3 fill-primary-disabled hover:!fill-primary-disabled'
                    }
                  />
                  <div className="flex flex-col">
                    {moment(event.start as Date).format('hh:mm A')} -{' '}
                    {moment(event.end as Date).format('hh:mm A')}
                    <p>({user.timezone})</p>
                  </div>
                </IconWithLabel>

                {event?.externalReferenceIds?.length ? (
                  <div className="flex flex-col">
                    {event?.externalReferenceIds?.map((externalReference, index) => (
                      <ExternalMeetingStatus key={index} externalReferenceId={externalReference} />
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </MeetingDetailsHeader>
              <div className="px-6">
                <Divider />
              </div>
              <MeetingDetails
                user={user}
                showEditButton={showEditButton}
                setShowEditButton={setShowEditButton}
                event={event}
                isEdit={true}
                closeModal={closeModal}
                onSubmit={onSubmit}
                setDescription={setDescription}
                description={description}
                // cancelEventHandler={cancelEventHandler}
                videoLinkDescription={''}
                isDisabled={isDisabled}
              />
            </>
          </EditMeetingModal>
        )}
    </>
  )
}

CalendarEventEditModal.defaultProps = {
  timeMin: '0',
  timeMax: '0',
  showEditButton: false,
  setShowEditButton: () => {},
  meetingType: '',
  openMeetingConfirmationModalBoxAction: () => {},
  getTackleMeetingEventsAction: () => {},

  stepType: '',
}
