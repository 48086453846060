import { createStore, applyMiddleware, compose as reduxCompose, StoreEnhancer } from 'redux'
import createSagaMiddleware from 'redux-saga'
import sagaMonitor from '@redux-saga/simple-saga-monitor'
import rootSaga from 'store/sagas'
import rootReducer from 'store/reducers'
import { enhancer as withReduxEnhancer } from 'addon-redux'
// import { ANNOUNCEMENT_NAMES } from 'config'

export default function configureStore() {
  const devToolsCompose = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as
    | typeof reduxCompose
    | undefined

  const compose = devToolsCompose || reduxCompose

  const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
  const middleware = applyMiddleware(sagaMiddleware)

  const usingReduxAddon =
    process.env.NODE_ENV !== 'production' || process.env.STORYBOOK_ENABLE_REDUX_ADDON_ENHANCER
  if (usingReduxAddon) {
    console.log('Using Storybook redux addon.')
  }

  const typedWithReduxEnhancer: StoreEnhancer<typeof middleware> = withReduxEnhancer

  const enhancers = usingReduxAddon
    ? /** The order of these arguments is required. Storybook stories will not properly load data unless withReduxEnhancer is first */
      compose(typedWithReduxEnhancer, middleware)
    : compose(middleware)

  // todo - type this. Solution not obvious
  // @ts-ignore
  const store = createStore(
    rootReducer,
    {},
    // @ts-ignore
    enhancers
  )

  sagaMiddleware.run(rootSaga)
  return store
}
