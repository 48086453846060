import moment from 'moment'
import sortBy from 'lodash/sortBy'
import pick from 'lodash/pick'

import history from 'utils/history'

import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import {
  GET_ADVISORS_DASHBOARD,
  GET_CLIENTS,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_CLIENT_EDIT_PROFILE_DETAIL,
  GET_TOP_ACTIVE_CLIENTS,
  UPDATE_CLIENT_PROFILE_DETAIL,
  GET_CLIENT_ASSESSMENTS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_ALL_TOOLS,
  GET_TACKLE_MEETING_EVENTS,
  DE_AUTHENTICATE_TOOLS,
  UPDATE_MY_TOOLS,
  GET_MY_TOOLS,
  SET_AUTH_TOOLS_SUCCESS,
  UPDATE_USER_CALENDAR_EVENT,
  GET_CLIENT_ATTESTATION_REPORT,
  GET_TOOLS_BY_TYPE,
  CANCEL_TACKLE_MEETING_EVENT,
  GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS,
  UPDATE_BOOKMARK_TOOL,
} from 'store/types'
import CalendarApi from 'api/calendar'
import ClientsApi from 'api/clients'
import {
  getClientActivityLogsAction,
  getClientActivityScoreAction,
  getClientProfileAction,
  getClientProfileDetailsAction,
  getClientEditProfileDetailAction,
  getAdvisorDashboardAction,
  getTopActiveClientsAction,
  getClientsAction,
  updateClientProfileDetailAction,
  getAssessmentListAction,
  getAssessmentAction,
  getAllToolsAction,
  getTackleMeetingEventsAction,
  getMyToolsAction,
  toolConnectionSuccessAction,
  deauthenticateConnectorAction,
  getClientAttestationReportAction,
  getToolsByTypeAction,
  CancelTackleMeetingPayload,
  getClientRecommendedPlaysAssessments,
  ExtendedBookmarkToolInput,
  cancelTackleMeetingEventAction,
} from 'store/actions/clients'
import UserApi from 'api/user'
import AssessmentApi from 'api/assessment'
import { cloneDeep, get, isEmpty, pickBy } from 'lodash'
import hookForms from 'utils/hookForms'
import { FORM, MEETING_STEPPER, formKeys, toolsTabs } from 'config'
import goalAPi from 'api/goal'
import {
  getBusinessAndAssessmentResponseAction,
  getUserProfileAndAdvisorsAction,
} from 'store/actions/userManagement'
import { getUserGoalAction } from 'store/actions/owner/playbooks'
import { getConnectedToolsAction } from 'store/actions/clients'
import { getUserAssessments } from 'store/sagas/owner'
import { setFormData } from 'store/actions/form'
import {
  getUserCalendarEventsAction,
  getUserConnectorsAction,
  openMeetingConfirmationModalBoxAction,
  updateUserCalendarEventAction,
} from 'store/actions/calendar'
import { isSameObject } from 'utils/helper'
import { getUserProfileAction, updateMyToolsAction } from 'store/actions/user'
import { ampli } from 'ampli'
import { PaginationDirection, Tool, ToolType } from '__generated__/api-types-and-hooks'
import { Action } from 'types'
import { setToolConnectionStatus } from '../actions/common'

function* getAdvisorDashboard(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }

    let res = yield call(ClientsApi.getAdvisorDashboard, payload)
    res = JSON.parse(res?.getAdvisorDashboard.data)
    let list = res?.accounts
    let clientsData = res
    if (action.payload?.filterType) {
      yield put(getAdvisorDashboardAction.FULLFILLED({ clientsData }))
    } else {
      yield put(
        getAdvisorDashboardAction.FULLFILLED({
          list,
          clientsData,
          isAllClientFetching: false,
          isDataFetched: true,
        })
      )
    }
  } catch (error) {
    yield put(getAdvisorDashboardAction.REJECTED(error))
    console.log(error)
  }
}

function* getClients(action) {
  const tenantId = yield select((state) => state.user?.tenantId)

  try {
    const payload = { ...action.payload, tenantId }
    yield put(getClientsAction.FULLFILLED([]))
    let res = yield call(ClientsApi.getClients, payload)
    res = get(res, 'getClients.data')
    yield put(getClientsAction.FULLFILLED(res))
  } catch (error) {
    yield put(getClientsAction.REJECTED(error))
    console.log(error)
  }
}
function* getTopActiveClients(a) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    let res = yield call(ClientsApi.getTopActiveClients, { tenantId })
    const data = get(res.getTopActiveClients, 'data')
    yield put(getTopActiveClientsAction.FULLFILLED(data))
  } catch (error) {
    yield put(getTopActiveClientsAction.REJECTED(error))
    console.log(error)
  }
}

function* getClientProfile(action) {
  try {
    if (!action?.payload) return

    const res = yield call(UserApi.getUserProfile, action?.payload?.clientId)
    const userProfile = get(res, 'getMyProfile', {})

    const currentDate = moment().startOf('day')
    const totalDays = userProfile?.firstSigninDate
      ? currentDate.diff(moment(userProfile?.firstSigninDate).startOf('day'), 'days')
      : 0

    const clientProfile = {
      ...userProfile,
      totalDays,
    }

    yield put(getClientProfileAction.FULLFILLED(clientProfile))
  } catch (error) {
    console.log(error)
  }
}

function* getClientActivityLogs(action) {
  try {
    const { clientId } = action.payload
    const tenantId = yield select((state) => state.user?.tenantId)

    let activityLogs = yield call(ClientsApi.getActivityLogs, clientId, tenantId)
    activityLogs = get(activityLogs, 'getActivityLogs.data', [])

    if (activityLogs) {
      activityLogs = JSON.parse(activityLogs)
      yield put(getClientActivityLogsAction.FULLFILLED(activityLogs))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getClientActivityScore(action) {
  try {
    const { clientId } = action?.payload

    const tenantId = yield select((state) => state.user?.tenantId)

    const res = yield call(ClientsApi.getOwnerActivityDetails, clientId, tenantId)
    let activityDetail = res?.getOwnerActivityDetails?.data
    if (activityDetail) {
      activityDetail = JSON.parse(activityDetail)
      yield put(getClientActivityScoreAction.FULLFILLED(activityDetail))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getClientProfileDetails(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const clientId = action?.payload?.clientId
    const userGoals = yield call(goalAPi.getUserGoals)
    yield put(getUserGoalAction.FULLFILLED(userGoals?.getUserGoals))

    const [userProfile, businessProfile] = yield all([
      call(getClientProfile, { payload: { clientId, tenantId } }),
      call(UserApi.getBusinessProfile, clientId),
    ])

    const payload = {
      userProfile,
      businessProfile: businessProfile?.getBusinessProfile
        ? businessProfile?.getBusinessProfile
        : {},
    }
    yield put(getClientProfileDetailsAction.FULLFILLED(payload))
  } catch (error) {
    console.log(error)
  }
}

function* getClientProfileDetail(action) {
  const { userId } = action.payload
  const tenantId = yield select((state) => state.user?.tenantId)

  try {
    yield all([
      put(
        getUserProfileAndAdvisorsAction.STARTED({
          tenantId,
          userId,
          setForms: [FORM.USER_PROFILE_FORM],
        })
      ),
      put(
        getBusinessAndAssessmentResponseAction.STARTED({
          setForms: [FORM.CLIENT_BUSINESS_PROFILE],
          userId,
          tenantId,
          type: 'initial',
        })
      ),
    ])

    const res = yield call(UserApi.getUserProfile, userId)
    delete res?.getMyProfile?.topGoal
    let otherBusinessDetails = yield select((state) => state.user.businessProfile)

    const otherBusinessDetail = { ...otherBusinessDetails, ...res.getMyProfile }
    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      let businessOtherDetail = pickBy(otherBusinessDetail, (value, key) =>
        formKeys[FORM.BUSINESS_OTHER_DETAILS].includes(key)
      )

      yield put(setFormData({ form: setForms[0], data: businessOtherDetail }))
    }
    yield put(getClientEditProfileDetailAction.FULLFILLED({ update: true }))
  } catch (error) {
    console.log(error)
  }
}

function* updateClientProfileDetail(action) {
  const { userId } = action.payload
  const form = yield select((state) => state.form)
  const tenantId = yield select((state) => state.user?.tenantId)
  try {
    let userProfileFormValues = hookForms.getForm(FORM.USER_PROFILE_FORM).getValues()
    const businessOtherDetailsFormValue = hookForms.getForm(FORM.BUSINESS_OTHER_DETAILS).getValues()
    const businessProfileFormValues = hookForms.getForm(FORM.CLIENT_BUSINESS_PROFILE).getValues()

    const businessProfile = {
      ...businessProfileFormValues,
      ...businessOtherDetailsFormValue,
    }

    userProfileFormValues.mobileContactNumber = userProfileFormValues?.mobileContactNumber?.replace(
      /\D/g,
      ''
    )

    delete businessProfileFormValues.topGoal
    delete userProfileFormValues?.advisors
    delete userProfileFormValues?.email

    const apiCalls: any = []
    if (
      !isSameObject(
        userProfileFormValues,
        pick(form.USER_PROFILE_FORM, ['firstName', 'lastName', 'mobileContactNumber'])
      )
    ) {
      apiCalls.push(
        call(UserApi.updateUserProfile, {
          ...userProfileFormValues,
          userId,
          tenantId,
        })
      )
    }

    if (
      !isSameObject(businessProfileFormValues, form.USER_MANAGEMENT_BUSINESS_FORM) ||
      !isSameObject(businessOtherDetailsFormValue, form.BUSINESS_OTHER_DETAILS)
    )
      apiCalls.push(
        call(UserApi.updateBusinessProfile, {
          ...businessProfile,
          id: userId,
          tenantId,
        })
      )

    if (apiCalls.length > 0) yield all(apiCalls)

    const payload = { clientId: userId }
    yield call(getClientProfileDetails, { payload })
    yield put(updateClientProfileDetailAction.FULLFILLED())
  } catch (error: any) {
    console.log('error: ', error)
    if (error.message.includes('mobileContactNumber')) {
      const mobileContactError = { message: 'Please enter valid phone number' }
      yield put(updateClientProfileDetailAction.REJECTED({ mobileContactError }))
    } else {
      const errors = error.messages
      yield put(updateClientProfileDetailAction.REJECTED({ error: errors }))
    }
  }
}

export type Payload = {
  userId: string
}

function* getAssessmentList(action: Action<string, Payload>) {
  try {
    const { userId } = action.payload
    const tenantId = yield select((state) => state.user?.tenantId)
    let getAssessments = yield call(AssessmentApi.getAssessmentList, userId, tenantId)
    yield put(getAssessmentListAction.FULLFILLED(getAssessments.listAssessments.data))
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getAssessment(action) {
  try {
    const { type, userId } = action.payload
    let getAssessments = yield call(AssessmentApi.getAssessments, type)
    getAssessments = getUserAssessments(getAssessments, false)
    getAssessments = sortBy(getAssessments, (q) => q.previous)
    let questions: any = { assessmentResponse: [] }
    const res = yield call(AssessmentApi.getUserAssessmentResponse, type, userId)
    questions = get(res, 'getUserAssessmentResponse.data', '')
    const assessmentResponse = {}
    questions.assessmentResponse = get(questions, 'assessmentResponse')
      ? JSON.parse(questions.assessmentResponse)
      : []
    questions.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })

    yield put(
      getAssessmentAction.FULLFILLED({
        [type]: {
          questions: getAssessments,
          assessmentResponse: questions,
        },
      })
    )
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getConnectedTools() {
  try {
    const user = yield select((state) => ({
      userId: get(state?.clients, 'client.profile.id'),
      tenantId: state.user?.tenantId,
    }))
    let getConnectedTools = yield call(
      ClientsApi.getClientConnectedTools,
      user.userId,
      user.tenantId
    )
    let data = get(getConnectedTools, 'getUserIntegrations.data')
    if (data) data = JSON.parse(data)
    yield put(getConnectedToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getAllTools(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const payload = { ...action.payload, tenantId }
    const res = yield call(ClientsApi.getAllTools, payload)
    let data: Tool[] = get(res, 'getAllTools.data')
    if (data) data = data as Tool[]
    yield put(getAllToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getTackleMeeting(payloadData?: any) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const payload = {
      tenantId,
      filter: payloadData?.payload?.filter.trim(),
      isPaginated: false,
      ownerId: payloadData?.payload?.ownerId,
    }

    let data = yield call(ClientsApi.getTackleMeetings, payload)

    yield put(
      getTackleMeetingEventsAction.FULLFILLED({
        data: get(data, 'getTackleMeetings.data', []),
        filter: payloadData?.payload?.filter.trim(),
      })
    )
  } catch (error) {
    console.log('get GoTackle meetings error : ', error)
  }
}

function* updateUserCalendarEvent(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const { updateCalendarMeetingPayload, getCalendarEventPayload, updateCommentPayload } =
      action.payload

    let res = yield call(
      CalendarApi.updateUserCalendarEvent,
      updateCalendarMeetingPayload ? updateCalendarMeetingPayload : updateCommentPayload
    )
    if (!res?.updateUserCalendarEvent) {
      if (getCalendarEventPayload) {
        yield put(updateUserCalendarEventAction.REJECTED())
        yield put(
          openMeetingConfirmationModalBoxAction({
            error: 'Meeting not updated',
            stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
          })
        )
      }
      return
    }

    if (getCalendarEventPayload) {
      yield put(getUserCalendarEventsAction.STARTED({ ...getCalendarEventPayload, tenantId }))

      yield put(updateUserCalendarEventAction.FULLFILLED({}))
      yield put(
        openMeetingConfirmationModalBoxAction({
          error: '',
          stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
        })
      )
    }
    if (updateCommentPayload) {
      yield put(updateUserCalendarEventAction.FULLFILLED(res?.updateUserCalendarEvent?.data))
    }
  } catch (error: any) {
    console.log('comment updating error : ', error)
    yield put(updateUserCalendarEventAction.REJECTED())
    yield put(
      openMeetingConfirmationModalBoxAction({
        error: error.message,
        stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
      })
    )
  }
}

function* deauthenticateConnector(action) {
  const tenantId = yield select((state) => state.user?.tenantId)

  yield call(ClientsApi.deauthenticateConnector, action.payload, tenantId)
  yield put(deauthenticateConnectorAction.FULLFILLED(action))
  ampli.toolDisconnected({ name: action.payload })
  yield call(getMyTools)
  yield call(getToolsByType, { payload: { tenantId, toolType: ToolType.Calendar } })
  yield call(getAllTools, { payload: { tenantId } })
  yield put(getUserConnectorsAction.STARTED(undefined))
}

function* cancelTackleMeetingEvent(action) {
  const { deleteEventPayload, getTackleMeetingPayload }: CancelTackleMeetingPayload = action.payload
  const tenantId = yield select((state) => state.user?.tenantId)
  try {
    yield call(CalendarApi.cancelUserCalendarEvent, {
      ...deleteEventPayload,
      tenantId,
    })

    yield put(cancelTackleMeetingEventAction.FULLFILLED())
    yield put(
      openMeetingConfirmationModalBoxAction({
        error: '',
        stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
        isMeetingDeletion: true,
      })
    )
    const payload = {
      ...getTackleMeetingPayload,
    }
    yield call(getTackleMeeting, { payload })
  } catch (error: any) {
    yield put(cancelTackleMeetingEventAction.REJECTED())
    yield put(
      openMeetingConfirmationModalBoxAction({
        error: error?.message,
        stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
        isMeetingDeletion: true,
      })
    )
    console.log('cannot cancel meeting error : ', error)
  }
}
function* updateMyTools(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }
    const res = yield call(ClientsApi.updateMyTools, payload)
    yield put(updateMyToolsAction.FULLFILLED(res))
    if (action.payload.ownerExperience) {
      const response = yield call(UserApi.getUserProfile)

      const user = response?.getMyProfile
      yield put(getUserProfileAction.FULLFILLED({ user }))
    } else {
      yield call(getMyTools)
    }
  } catch (error) {
    console.log('error while updating: ', error)
  }
}
function* getMyTools() {
  try {
    const payload = yield select((state) => ({
      tenantId: state.user.tenantId,
      userId: state.user.user.id,
    }))
    const res = yield call(ClientsApi.getMyTools, payload.userId, payload.tenantId)
    let data = get(res, 'getUserTools.data')
    if (data) data = data as Tool[]
    yield put(getMyToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('error while updating: ', error)
  }
}

function* toolConnectionSuccess(action?: { payload: { connectorName: string; playId?: string } }) {
  const connectorName = action?.payload?.connectorName ?? ''
  if (!connectorName) {
    yield put(toolConnectionSuccessAction.REJECTED())
  }
  try {
    const { user, play, businessProfile, tenantId, myTools } = yield select((state) => ({
      user: state.user.user,
      play: state.play.play,
      businessProfile: state.user.businessProfile,
      tenantId: state.user?.tenantId,
      myTools: state.clients?.myTools,
    }))

    const locationTriggered = history.location.pathname.split('/')[3]
    const isToolReconnected =
      myTools?.find((tool) => tool?.toolName === connectorName)?.connectionStatus !== null

    yield call(ClientsApi.toolConnectionSuccess, connectorName, tenantId, action?.payload.playId)
    const sessionToken = localStorage.getItem('sessionToken')

    yield call(UserApi.logActivity, {
      action: `Integrated ${connectorName}`,
      logStatus: '',
      accessToken: sessionToken,
      tenantId,
      showClientActivity: true,
      type: get(play, 'id', ''),
    })

    isToolReconnected
      ? ampli.toolReconnected({
          name: `${user?.firstName} ${user?.lastName}`,
          locationTriggered: locationTriggered,
          roles: user?.roles,
          toolName: connectorName,
          bsoOrgName: user?.organizationName,
          businessName: businessProfile?.businessName,
        })
      : ampli.toolConnected({ name: connectorName ?? '', playId: action?.payload.playId })
    yield put(toolConnectionSuccessAction.FULLFILLED())
    yield put(
      setToolConnectionStatus({
        toolName: connectorName,
        isConnected: true,
      })
    )
  } catch (error) {
    console.log('error while updating: ', error)
  }
}
function* getToolsByType(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const payload = { ...action.payload, tenantId }
    const res = yield call(ClientsApi.getToolsByType, payload)
    let data = get(res, 'getToolsByType.data')
    if (data) data = data as Tool[]
    yield put(getToolsByTypeAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getClientAttestationReport(action) {
  try {
    const clientReport = yield select((state) => state.clients.clientReport)

    const tenantId = yield select((state) => state.user?.user?.tenantId?.[0])
    let lastEvaluatedKey = undefined
    const prevEvaluatedKey = get(clientReport, 'prevEvaluatedKey')

    if (action?.payload?.viewMore) {
      lastEvaluatedKey = get(clientReport, 'lastEvaluatedKey')
      if (isEmpty(lastEvaluatedKey)) return
    }

    const payload = {
      tenantId,
      pageSize: action?.payload?.pageSize || 10,
      page: action?.payload?.currentPage || 1,
      filter: action?.payload?.filter,
      lastEvaluatedKey:
        action?.payload?.paginationDirection === PaginationDirection.Backward
          ? prevEvaluatedKey
          : lastEvaluatedKey,
      month: moment().format('MM'),
      year: moment().format('YYYY'),
      sort: action?.payload?.sort,
      paginationDirection: action?.payload?.paginationDirection ?? PaginationDirection.Forward,
    }

    if (action.payload.deleteLastEvaluatedKey) delete payload.lastEvaluatedKey
    const response = yield call(ClientsApi.getAttestationReport, payload)
    yield put(getClientAttestationReportAction.FULLFILLED(response?.getAttestationReports?.data))
  } catch (error) {
    console.log('error while updating: ', error)
  }
}

function* getRecommendedPlaysAssessment() {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const clientId = yield select((state) => state.clients?.client?.profile?.id)
    const res = yield call(AssessmentApi.getUserRecommendations, {
      tenantId,
      businessId: clientId,
    })
    let record = JSON.parse(res?.getUserRecommendations?.data || '{}')
    yield put(getClientRecommendedPlaysAssessments.FULLFILLED(record?.clientRecommendations || []))
  } catch (error) {
    console.log('recommended play assessment error ', error)
  }
}

function* updateBookmarkTool(action: Action<string, ExtendedBookmarkToolInput>) {
  const { isBookmarked, toolId, toolSubTab, bookMarkResponseData } = action.payload
  try {
    let allTools: Tool[] = yield select((state) => state.clients.allTools)
    let userTools: Tool[] = yield select((state) => state.clients.myTools)
    if (toolSubTab === toolsTabs[0].link) {
      if (isBookmarked) {
        userTools = [...userTools, bookMarkResponseData]
      } else {
        userTools = userTools.filter((userTool) => userTool.id !== toolId) || []
      }
      yield put(getMyToolsAction.FULLFILLED(cloneDeep(userTools)))
    } else {
      if (isBookmarked) {
        allTools.forEach((allTool) => {
          if (allTool.id === toolId) allTool.isBookmarked = true
        })
      } else {
        allTools.forEach((allTool) => {
          if (allTool.id === toolId) allTool.isBookmarked = false
        })
      }
      yield put(getAllToolsAction.FULLFILLED(cloneDeep(allTools)))
    }
  } catch (error) {
    console.log('error bookmarking tool ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherClients() {
  yield takeLatest(GET_CLIENT_ASSESSMENT_LIST.STARTED, getAssessmentList)
  yield takeLatest(GET_ADVISORS_DASHBOARD.STARTED, getAdvisorDashboard)
  yield takeLatest(GET_TOP_ACTIVE_CLIENTS.STARTED, getTopActiveClients)
  yield takeLatest(GET_CLIENT_PROFILE.STARTED, getClientProfile)
  yield takeLatest(GET_CLIENT_ACTIVITY_LOGS.STARTED, getClientActivityLogs)
  yield takeLatest(GET_CLIENT_ACTIVITY_SCORE.STARTED, getClientActivityScore)
  yield takeLatest(GET_CLIENT_PROFILE_DETAILS.STARTED, getClientProfileDetails)
  yield takeLatest(GET_CLIENTS.STARTED, getClients)
  yield takeLatest(GET_CLIENT_EDIT_PROFILE_DETAIL.STARTED, getClientProfileDetail)
  yield takeLatest(UPDATE_CLIENT_PROFILE_DETAIL.STARTED, updateClientProfileDetail)
  yield takeLatest(GET_CLIENT_ASSESSMENTS.STARTED, getAssessment)
  yield takeLatest(GET_CLIENT_CONNECTED_TOOLS.STARTED, getConnectedTools)
  yield takeLatest(GET_ALL_TOOLS.STARTED, getAllTools)
  yield takeLatest(GET_TACKLE_MEETING_EVENTS.STARTED, getTackleMeeting)
  yield takeLatest(UPDATE_USER_CALENDAR_EVENT.STARTED, updateUserCalendarEvent)
  yield takeLatest(DE_AUTHENTICATE_TOOLS.STARTED, deauthenticateConnector)
  yield takeLatest(CANCEL_TACKLE_MEETING_EVENT.STARTED, cancelTackleMeetingEvent)
  yield takeLatest(UPDATE_MY_TOOLS.STARTED, updateMyTools)
  yield takeLatest(GET_MY_TOOLS.STARTED, getMyTools)
  yield takeLatest(SET_AUTH_TOOLS_SUCCESS.STARTED, toolConnectionSuccess)
  yield takeLatest(GET_TOOLS_BY_TYPE.STARTED, getToolsByType)
  yield takeLatest(GET_CLIENT_ATTESTATION_REPORT.STARTED, getClientAttestationReport)
  yield takeLatest(GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED, getRecommendedPlaysAssessment)
  yield takeLatest(UPDATE_BOOKMARK_TOOL, updateBookmarkTool)
}
