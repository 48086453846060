import { ApiResponseError } from 'utils/error-handling/api-response-error'
import { updateUserProfileAction } from '../../../../store/actions/user'
import { store } from 'App'
import { handleErrors } from 'utils/error-handling'

export const handleUpdateUserProfileError = (errors: ApiResponseError[]) => {
  for (const error of errors) {
    if (error.message.includes('mobileContactNumber')) {
      const mobileContactError = { message: 'Please enter valid phone number' }
      store.dispatch(updateUserProfileAction.REJECTED({ mobileContactError }))
    } else {
      store.dispatch(updateUserProfileAction.REJECTED({ updateUserProfileErrors: errors }))
      handleErrors(errors)
    }
  }
}
