import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class CLIENTS_API {
  getClients = ({ advisorId, tenantId, includeInvitedClients }): Promise<any> => {
    return API.fetch(api['GetClientsDocument'], {
      input: { advisorId, tenantId, includeInvitedClients },
    })
  }
  getAdvisorDashboard = ({ filterType, tenantId }): Promise<any> => {
    return API.fetch(api['GetAdvisorDashboardDocument'], { filterType, tenantId })
  }
  getTopActiveClients = ({ tenantId }): Promise<any> => {
    return API.fetch(api['GetTopActiveClientsDocument'], { tenantId })
  }

  getOwnerActivityDetails = (ownerId, tenantId): Promise<any> => {
    return API.fetch(api['GetOwnerActivityDetailsDocument'], { ownerId, tenantId })
  }

  getActivityLogs = (userId, tenantId): Promise<any> => {
    return API.fetch(api['GetActivityLogsDocument'], { userId, tenantId })
  }

  getClientConnectedTools = (userId = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetUserIntegrationsDocument'], { userId, tenantId })
  }
  getTackleMeetings = (payload): Promise<any> => {
    return API.fetch(api['GetTackleMeetingsDocument'], { input: payload })
  }
  deauthenticateConnector = (connectorName = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['DeauthenticateConnectorDocument'], { input: { connectorName, tenantId } })
  }
  getAllTools = (input): Promise<api.GetAllToolsResponse | undefined> => {
    return API.fetch(api['GetAllToolsDocument'], {
      tenantId: input.tenantId,
      type: input.type,
    })
  }
  getToolsByType = (input): Promise<api.GetToolsByTypeResponse | undefined> => {
    return API.fetch(api['GetToolsByTypeDocument'], {
      input: {
        toolType: input.toolType,
        tenantId: input.tenantId,
      },
    })
  }

  updateMyTools = (payload): Promise<any> => {
    return API.fetch(api['UpdateOwnerDocument'], { input: payload })
  }
  getMyTools = (
    userId = undefined,
    tenantId = undefined
  ): Promise<api.GetUserToolsResponse | undefined> => {
    return API.fetch(api['GetUserToolsDocument'], { userId, tenantId })
  }
  toolConnectionSuccess = (
    connectorName?: string,
    tenantId?: string,
    playId?: string
  ): Promise<any> => {
    return API.fetch(api['ToolConnectionSuccessDocument'], {
      input: { connectorName, tenantId, playId },
    })
  }
  getAttestationReport = (payload): Promise<api.GetAttestationReportsResponse | undefined> => {
    return API.fetch(api['GetAttestationReportsDocument'], { input: payload })
  }
}

// eslint-disable-next-line
export default new CLIENTS_API()
