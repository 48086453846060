import React, { useEffect, useMemo, useState } from 'react'

import CLIENTS_API from 'api/clients'

import ProfileSection from 'components/Dashboard/ProfileSection'
import NavigationCards from 'components/Dashboard/NavigationCards'
import UpcomingEvents from 'components/Dashboard/UpcomingEvents'
import StatCards from 'components/Dashboard/StatCards'
import { useTenantId } from 'utils/useTenantId'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { USER_ROLES } from 'config'
import TimezoneModal from '../Calendar/TimezoneModal'
import moment from 'moment-timezone'
import Toast from 'components/Common/Toast'
import { launchUsetifulIfNeeded } from 'utils/helper'
import { isEmpty } from 'lodash'
import { CalendarEventViewPreference } from '__generated__/api-types-and-hooks'

const useActiveClients = (): [any[], boolean, boolean] => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [clients, setClients] = useState<any[]>([])
  const tenantId = useTenantId()

  useEffect(() => {
    setLoaded(false)
    setError(false)
    const getActiveClients = async () => {
      try {
        // @ts-ignore
        const response = await CLIENTS_API.getAdvisorDashboard({ tenantId })
        const { accounts } = JSON.parse(response?.getAdvisorDashboard?.data)
        setClients(accounts || [])
      } catch (error) {
        setClients([])
        setError(true)
      } finally {
        setLoaded(true)
      }
    }
    getActiveClients()
  }, [tenantId])

  return [clients, loaded, error]
}

const Dashboard = ({
  getUserCalendarEventsAction,
  getUserNoticationsAction,
  updateUserNoticationsAction,
  getClientsSummaryAction,
  updateUserProfileAction,
  chatUnreadMessageCount,
  isUpdateProfileLoading,
  notificationList,
  isLoading,
  summary,
  events,
  user,
}) => {
  const [showTimezoneModal, setShowTimezoneModal] = useState(false)
  const [toastId, setToastId] = useState()

  useEffect(() => {
    getClientsSummaryAction()
    getUserCalendarEventsAction({
      calendarName:
        user.calendarEventViewPreference === CalendarEventViewPreference.Internal
          ? CalendarEventViewPreference.Internal
          : '',
    })
    if (user.id) {
      getUserNoticationsAction()
    }
    if (user?.timezone && user.timezone !== moment.tz.guess()) {
      setShowTimezoneModal(true)
    }
    if (!isEmpty(user?.id)) {
      launchUsetifulIfNeeded()
    }
  }, [getClientsSummaryAction, getUserCalendarEventsAction, getUserNoticationsAction, user])

  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)

  const [clients, clientsLoaded, errorFetchingClients] = useActiveClients()
  const onTimezoneModalClose = async () => {
    updateUserProfileAction({
      interimTimezone: moment.tz.guess(),
    })
    setShowTimezoneModal(false)
    await launchUsetifulIfNeeded()
  }

  const showNoActiveClientsMessage = useMemo(() => {
    return clients?.length === 0 && clientsLoaded && !errorFetchingClients
  }, [clients, clientsLoaded, errorFetchingClients])

  /** These are events parsed into the interface required by the UpcomingEvents component */
  const eventSummaries = useMemo(() => {
    return events ? events.map((event) => ({ description: event.title, date: event.start })) : []
  }, [events])

  let logoUrl = ''
  let bsoName = ''
  const isBSO =
    user.roles?.includes(USER_ROLES.BSO_ADMIN) || user.roles?.includes(USER_ROLES.BSO_ADVISOR)
  if (isBSO) {
    bsoName = bsoProfile.name
    logoUrl = bsoProfile.logoUrl
  }

  return (
    <div className="w-full max-w-full">
      <div className="absolute w-full ">
        {notificationList?.map((notification, index) => {
          const meta = JSON.parse(notification.meta)

          return (
            <div className="inline-block" key={index}>
              {toastId !== notification.id && (
                <Toast
                  variant="info"
                  showCloseButton={true}
                  onClose={() => {
                    updateUserNoticationsAction({ id: notification.id, getNotifications: true })
                    setToastId(notification.id)
                  }}
                >
                  {meta.text}
                </Toast>
              )}
            </div>
          )
        })}
      </div>
      <ProfileSection
        isBSO={isBSO}
        firstName={user.firstName}
        lastName={user.lastName}
        profileImgSrc={logoUrl}
        bsoName={bsoName}
      />
      <NavigationCards chatUnreadMessageCount={chatUnreadMessageCount} />
      <div className="flex flex-col gap-8 mt-11 sm:mt-[81px] w-full max-w-full lg:flex-row">
        <StatCards
          showNoActiveClientsMessage={showNoActiveClientsMessage}
          loading={isLoading}
          stats={summary}
        />
        <UpcomingEvents
          className="lg:order-first"
          noEventsMessage="Get started on your Calendar"
          events={eventSummaries}
          loading={isLoading}
        />
      </div>
      {showTimezoneModal &&
      (!user?.interimTimezone || user?.interimTimezone !== moment.tz.guess()) ? (
        <TimezoneModal
          onClose={onTimezoneModalClose}
          setShowTimezoneModal={setShowTimezoneModal}
          updateUserProfileAction={updateUserProfileAction}
          defaultTimezone={user?.timezone}
        ></TimezoneModal>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Dashboard
