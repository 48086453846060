import React, { useEffect } from 'react'

import useFormContainer from 'hooks/useFormContainer'
import { useForm } from 'react-hook-form'

import { FORM } from 'config'
import hookForms from 'utils/hookForms'

import {
  AssignOwnerToAdvisorContainer,
  AssignOwnerToAdvisorMobileViewContainer,
} from 'pages/Admin/container/UserManagement'
import Button from 'components/Common/Button'
import Loader from 'components/Loaders'

const UserAssignment = ({
  handleOnSubmits,
  userAssignment,
  isOwnerDataLoading,
  getBusinessessAccountAction,
}) => {
  useEffect(() => {
    if (userAssignment === 'client') getBusinessessAccountAction({ allStatuses: false })
  }, [getBusinessessAccountAction, userAssignment])

  const assignUser = () => {
    const form = hookForms.getForm(FORM.OWNERS)
    const ownerAssignmentValues = form.getValues()
    ownerAssignmentValues['addUser'] = ownerAssignmentValues['owners']
    delete ownerAssignmentValues['owners']
    handleOnSubmits({ ...ownerAssignmentValues })
  }

  return isOwnerDataLoading ? (
    <Loader loader="ClientAssignmentLoader" />
  ) : (
    <div>
      <AssignOwnerToAdvisor onSubmit={handleOnSubmits} />
      <div className="flex flex-col items-center gap-4">
        <div className="w-[400px] flex justify-center">
          <Button
            type="button"
            label="Continue"
            testId="submit"
            variant="primary"
            className="w-full"
            onClick={() => assignUser()}
          />
        </div>
        <div className="flex justify-center">
          <Button
            type="button"
            label="Skip"
            testId="submit"
            variant="text"
            onClick={() => handleOnSubmits({})}
          />
        </div>
      </div>
    </div>
  )
}

export default UserAssignment

const AssignOwnerToAdvisor = ({ onSubmit }) => {
  const methods = useForm({
    mode: 'all',
  })
  useFormContainer(FORM.OWNERS, methods)

  return (
    <>
      <div className="xs:hidden sm:block">
        <AssignOwnerToAdvisorContainer
          onSubmit={onSubmit}
          padding=""
          height="h-[40vh]"
          methods={methods}
          isUserCreation={true}
        />
      </div>
      <div className="xs:block sm:hidden">
        <AssignOwnerToAdvisorMobileViewContainer
          onSubmit={onSubmit}
          methods={methods}
          padding="p-0"
        />
      </div>
    </>
  )
}
