import React from 'react'
import { AppFC } from 'types'

import useNavigateTo from 'hooks/useNavigateTo'

import {
  ChatFilledIcon,
  ClientsIcon,
  LineChartIcon,
  AddNewClientIcon,
} from 'components/Common/SvgIcons'
import NavigationCard from 'components/Common/NavigationCard'
import ActionCard from 'components/Common/ActionCard'
import useGetChatCountLabel from 'hooks/useGetChatCountLabel'

export interface INavigationCards {
  chatUnreadMessageCount?: number
}

/** A series of clickable cards that send the user to the chat, clients or data/reports page */
const NavigationCards: AppFC<INavigationCards> = ({ chatUnreadMessageCount = 0 }) => {
  const messageLabel = useGetChatCountLabel(chatUnreadMessageCount)
  const navigateToChat = useNavigateTo('chat')

  return (
    <div className="flex flex-col items-center gap-3 mt-[53px] max-w-[1050px] m-auto">
      <div className="grid grid-cols-2 smd:grid-cols-4 gap-2 sm:gap-4">
        <NavigationCard
          className="hidden smd:block"
          Icon={ChatFilledIcon}
          destination="chat"
          label={messageLabel}
        />
        <NavigationCard Icon={ClientsIcon} label="Clients" destination="clients" />
        <NavigationCard Icon={LineChartIcon} label="Data & Reports" destination="dataAndReports" />
        <NavigationCard
          Icon={AddNewClientIcon}
          label="Add a new client"
          destination="createBusinessOwner"
        />
        <div className="col-span-2 smd:hidden">
          <ActionCard onClick={navigateToChat}>
            <p className="font-semibold leading-4 text-base">{messageLabel}</p>
          </ActionCard>
        </div>
      </div>
    </div>
  )
}

export default NavigationCards
