import React, { useEffect, useState } from 'react'
import { getAuthToken, refreshToken } from 'lib/auth'
import { useLocation } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { Route } from 'react-router'
import { connect } from 'react-redux'
import { AnimateKeyframes } from 'react-simple-animate'
import history from 'utils/history'
import { ROLES } from 'config'
import { IdleTrackingContainer } from 'pages/Common/container'
import {
  getUserProfileAction,
  setTenantIdAction,
  updateUserProfileAction,
  getUnreadNotificationsAction,
} from 'store/actions/user'
import isEmpty from 'lodash/isEmpty'
import { logoutAction } from 'store/actions/auth'
import Logo from 'assets/images/GoTackle-Logo.svg'
import { getCurrentRoute } from 'utils/helper'
import redirectTo from 'utils/redirectTo'
import moment from 'moment-timezone'
import { getBSOProfileAction } from 'store/actions/bso'
/**
 * PrivateRoute is used to support the react router and it renders the routes
 * which is marked as private or is only accessible authenticated users
 * @param {React.Component} component
 */

const setTimezone = (timezone: string) => {
  moment.tz.setDefault(timezone ?? moment.tz.guess())
}
const PrivateRoute = ({
  user,
  component,
  userTenantId,
  logoutAction,
  getUserProfileAction,
  isUpdateProfileLoading,
  updateUserProfileAction,
  setTenantIdAction,
  getUnreadNotificationsAction,
  getBSOProfileAction,
  ...rest
}) => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUnreadNotificationsAction()
    // eslint-disable-next-line
  }, [window.location.href])

  useEffect(() => {
    ;(async () => {
      const jwtToken = await getAuthToken()
      if (!jwtToken) {
        const updatedSession = await refreshToken()
        if (!updatedSession.isValid()) {
          redirectTo('/auth/login', getCurrentRoute())
          return
        }
      }
    })()
  }, [])

  useEffect(() => {
    setLoading(true)
    if (isEmpty(user?.id)) {
      getUserProfileAction()
    } else {
      let found = false
      const roles = user.roles
      const pathName = location.pathname
      let tenantId = pathName?.split('/')[2]
      roles.forEach((role) => {
        const words = pathName.split('/')
        if (words.includes(ROLES[role])) {
          found = true
        }
      })
      if (isEmpty(userTenantId)) {
        tenantId = tenantId ? tenantId : user?.tenantId[0]
        setTenantIdAction(tenantId)
      }

      // get bso profile
      getBSOProfileAction(tenantId)

      if (!user?.timezone) {
        updateUserProfileAction({
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      }

      if (!found) {
        history.goBack()
      }
      setLoading(false)
      setTimezone(user?.timezone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const routeComponent = (props) => React.createElement(component, props)
  return (
    <>
      {!loading ? (
        <>
          <IdleTrackingContainer />
          <Route {...rest} render={routeComponent} />
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '46%',
            left: '42%',
          }}
        >
          <AnimateKeyframes
            play
            iterationCount="infinite"
            duration={2}
            keyframes={[
              'transform: translate(0px, 0px)',
              'transform: translate(200px, 0px)',
              'transform: translate(0px, 0px)',
            ]}
          >
            <img src={Logo} alt="GoTackle icon" className="object-contain h-[39px]" />
          </AnimateKeyframes>
        </div>
      )}
    </>
  )
}

const mapProps = (state: any) => {
  const user = state.user
  return {
    user: user?.user,
    userTenantId: user?.tenantId,
    isUpdateProfileLoading: state?.user?.isUpdateProfileLoading,
  }
}

function mapDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserProfileAction: getUserProfileAction.STARTED,
      getUnreadNotificationsAction: getUnreadNotificationsAction.STARTED,
      setTenantIdAction: setTenantIdAction,
      logoutAction: logoutAction,
      updateUserProfileAction: updateUserProfileAction.STARTED,
      getBSOProfileAction: getBSOProfileAction.STARTED,
    },
    dispatch
  )
}

export default connect(mapProps, mapDispatch)(PrivateRoute)
