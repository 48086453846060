import React from 'react'
import { AssessmentsContainer, ToolsContainer, PlaysContainer } from 'pages/Clients/container'
const Playbook = () => {
  return (
    <>
      <div className=" text-black-light grid xs:grid-cols-12 xs:gap-x-8 lg:gap-x-6 xs:gap-y-12 sm:gap-y-12 sm:pl-8 sm:pr-8 pt-6">
        <AssessmentsContainer />
        {/* <Plays /> */}
        <PlaysContainer />
        <ToolsContainer />
      </div>
    </>
  )
}

export default Playbook
