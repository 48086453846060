import map from 'lodash/map'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import upperFirst from 'lodash/upperFirst'
import find from 'lodash/find'
import range from 'lodash/range'
import size from 'lodash/size'
import filter from 'lodash/filter'
import get from 'lodash/get'
import each from 'lodash/each'
import isNil from 'lodash/isNil'
import toNumber from 'lodash/toNumber'
import first from 'lodash/first'
import join from 'lodash/join'
import compact from 'lodash/compact'
import { isArray, sortBy } from 'lodash'
import { formatCurrency } from 'utils/helper'

const parseArrayToObject = (data, key = 'id') => {
  const payload = {}
  each(data, (val) => (payload[val[key]] = val))
  return payload
}

const getAnswers = (answer, selectedOptions, isTextField) => {
  if (selectedOptions[0]?.uploadType === 'image') return answer?.value[0]
  if (isTextField) return get(answer, `value`, '')
  return join(compact(map(selectedOptions, `value`)), ', ')
}

/**
 * find first question and its selected option
 * save it in response payload
 * get the next id of question through selected value of previous selected option
 * find the next quyestion and its selected option
 * save it in response payload
 * @returns
 */
export const getAssessmentQuestions = ({
  questions,
  assessmentResponse: { assessmentResponse },
}) => {
  let isFlowBreaking: boolean = false
  const ownerQuestions = parseArrayToObject(
    filter(questions, (question) => question.meta.showToOwner)
  )
  const ownerAnswers = parseArrayToObject(assessmentResponse)
  let payload: any = []

  const assessmentResponseSize = size(assessmentResponse)

  for (let index = 0; index < assessmentResponseSize; index++) {
    let question: any = {}

    if (index === 0)
      question = find(ownerQuestions, (question) => size(get(question, 'previous')) === 0)
    else {
      question = get(ownerQuestions, `${get(payload[index - 1], 'nextQuestion')}`)
      if (isEmpty(question) && question?.options[0]?.action?.next?.default !== '') {
        isFlowBreaking = true
        payload = []
        break
      }
    }

    try {
      const answer = get(ownerAnswers, `${get(question, 'id')}`)
      let selectedOptions = filter(get(question, 'options'), (option) =>
        includes(get(answer, 'value'), option.id)
      )
      const isSelectedOptionEmpty =
        isEmpty(selectedOptions) &&
        (question?.optionType === 'multi-field' || size(get(question, 'options')) === 1)
      if (isSelectedOptionEmpty) selectedOptions.push(get(question, 'options[0]'))
      let answers = getAnswers(answer, selectedOptions, isSelectedOptionEmpty)
      if (question?.optionType === 'currency') answers = formatCurrency(answers)

      const data = {
        questionLabel: get(question, `content.owner`, ''),
        answer: answers,
        nextQuestion: get(first(selectedOptions), `action.next.default`, ''),
        showToClient: get(question, `meta.showToClientTools`, ''),
        multiInputQuestion: isArray(answers)
          ? get(question, 'options', []).map((option, index) => ({
              answer: answers[index]?.value,
              label: option?.label,
              questionLabel: get(question, `content.owner`, ''),
            }))
          : '',
      }

      payload.push(data)
    } catch (e) {
      console.log(e)
    }
  }

  if (isFlowBreaking) {
    map(range(size(assessmentResponse) + 1), (index) => {
      let question: any = {}
      const questionIdToFind = assessmentResponse[index]?.id
      const questionFound = ownerQuestions[questionIdToFind] || null
      question = questionFound

      if (!isEmpty(question)) {
        const answer = get(ownerAnswers, `${get(question, 'id')}`)
        let selectedOptions = filter(get(question, 'options'), (option) =>
          includes(get(answer, 'value'), option.id)
        )
        const isSelectedOptionEmpty =
          isEmpty(selectedOptions) &&
          (question?.optionType === 'multi-field' || size(get(question, 'options')) === 1)
        if (isSelectedOptionEmpty) selectedOptions.push(get(question, 'options[0]'))
        let answers = getAnswers(answer, selectedOptions, isSelectedOptionEmpty)
        if (question?.optionType === 'currency') answers = formatCurrency(answers)

        if (!isEmpty(answers)) {
          const data = {
            questionLabel: get(question, `content.owner`, ''),
            answer: answers,
            showToClient: get(question, `meta.showToClientTools`, ''),
            multiInputQuestion: isArray(answers)
              ? get(question, 'options', []).map((option, index) => ({
                  answer: answers[index]?.value,
                  label: option?.label,
                  questionLabel: get(question, `content.owner`, ''),
                }))
              : '',
          }
          payload.push(data)
        }
      }
    })
  }
  const toolsPayload = filter(
    payload,
    (question) => get(question, 'showToClient') && get(question, 'answer')
  )
  return toolsPayload
}

export const getAssessmentQuestionSelector = (state) => {
  const { clientAssessments } = state.clients
  let assesmentsResponse = {}
  each(clientAssessments, (assessment, key) => {
    assesmentsResponse = { ...assesmentsResponse, [key]: getAssessmentQuestions(assessment) }
  })
  return assesmentsResponse
}

export const getAssessmentListSelector = (state) => {
  const { clientAssessmentList } = state.clients

  return sortBy(
    map(clientAssessmentList, (assessment) => {
      return {
        ...assessment,
        title: upperFirst(lowerCase(assessment.type)),
        order: isNil(assessment.order) ? 0 : toNumber(assessment.order),
      }
    }),
    'order'
  )
}
