import {
  PlayResponse,
  SavePlayResponseInput,
  StepDataInput,
  UserFile,
} from '__generated__/api-types-and-hooks'
import {
  UPDATE_PLAY_STEP,
  GET_PLAY,
  AUTHORIZE_CONNECTOR,
  UPDATE_PLAY_STAGE,
  GET_PLAY_ASSESSMENT_RESPONSE,
  RESET_PLAY,
  HANDLE_COMPLETE_PLAY,
  GET_CONNECTED_PLAY,
  SAVE_PLAY_DATA,
  GET_PLAY_RESPONSE,
  SAVE_PLAY_RESPONSE,
  SAVE_USER_PLAY_FILE,
} from 'store/types'
import { CustomFile } from 'types'

export type GetPlayResponsePayload = {
  playId: string
  getResponse?: boolean
}
export type GetPlayResponsePayloadFulfilled = {
  PlayResponseData: PlayResponse
}

export type SaveUserFilePayload = {
  tabIndex: number
  stepIndex: number
  stepDataIndex: number
  files?: CustomFile[]
  stepData?: StepDataInput
}
export type SaveUserFileFulfilledPayload = {
  fileData: UserFile
}
export type SavePlayResponsePayload = SavePlayResponseInput & {
  redirectToDashboard?: boolean
  getResponse?: boolean
  savePlayResponse?: boolean
  playId: string
  stepData?: StepDataInput
  tabIndex?: number
  stepIndex: number
  submitActivity?: boolean
  name?: string
  isUpdateStage?: boolean
}

export const updatePlayStepId = {
  STARTED: (payload: any) => ({ type: UPDATE_PLAY_STEP.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_PLAY_STEP.FULLFILLED, payload }),
  REJECTED: () => ({ type: UPDATE_PLAY_STEP.REJECTED }),
}

export const getPlayAction = {
  STARTED: (payload: any) => ({ type: GET_PLAY.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_PLAY.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_PLAY.REJECTED }),
}

export const authorizeConnectorAction = {
  STARTED: (payload) => ({
    type: AUTHORIZE_CONNECTOR.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: AUTHORIZE_CONNECTOR.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: AUTHORIZE_CONNECTOR.REJECTED, payload: error }),
}

export const updatePlayStageAction = {
  STARTED: (payload) => ({
    type: UPDATE_PLAY_STAGE.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: UPDATE_PLAY_STAGE.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: UPDATE_PLAY_STAGE.REJECTED, payload: error }),
}

export const getPlayAssessmentResponseAction = {
  STARTED: (payload) => ({
    type: GET_PLAY_ASSESSMENT_RESPONSE.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_PLAY_ASSESSMENT_RESPONSE.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_PLAY_ASSESSMENT_RESPONSE.REJECTED, payload: error }),
}

export const resetPlayAction = () => ({
  type: RESET_PLAY,
})

export const handleCompletePlayAction = {
  STARTED: (payload: any) => ({ type: HANDLE_COMPLETE_PLAY.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: HANDLE_COMPLETE_PLAY.FULLFILLED, payload }),
  REJECTED: () => ({ type: HANDLE_COMPLETE_PLAY.REJECTED }),
}

export const getConnectedPlayAction = {
  STARTED: (payload: any) => ({ type: GET_CONNECTED_PLAY.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CONNECTED_PLAY.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_CONNECTED_PLAY.REJECTED }),
}
export const savePlayDataAction = {
  STARTED: (payload: any) => ({ type: SAVE_PLAY_DATA.STARTED, payload }),
  FULLFILLED: () => ({ type: SAVE_PLAY_DATA.FULLFILLED }),
  REJECTED: () => ({ type: SAVE_PLAY_DATA.REJECTED }),
}

export const getPlayResponseAction = {
  STARTED: (payload: GetPlayResponsePayload) => ({ type: GET_PLAY_RESPONSE.STARTED, payload }),
  FULLFILLED: (payload: PlayResponse) => ({
    type: GET_PLAY_RESPONSE.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_PLAY_RESPONSE.REJECTED }),
}
export const savePlayResponseAction = {
  STARTED: (payload: SavePlayResponsePayload) => ({ type: SAVE_PLAY_RESPONSE.STARTED, payload }),
  FULLFILLED: () => ({ type: SAVE_PLAY_RESPONSE.FULLFILLED }),
  REJECTED: () => ({ type: SAVE_PLAY_RESPONSE.REJECTED }),
}
export const saveUserPlayFileAction = {
  STARTED: (payload: SaveUserFilePayload) => ({ type: SAVE_USER_PLAY_FILE.STARTED, payload }),
  FULLFILLED: () => ({
    type: SAVE_USER_PLAY_FILE.FULLFILLED,
  }),
  REJECTED: () => ({ type: SAVE_USER_PLAY_FILE.REJECTED }),
}
