import {
  GET_ALL_TOOLS,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_MY_TOOLS,
  CLIENTS_SUMMARY,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
  GET_USER_CALENDAR_EVENTS,
  GET_BIG_QUERY,
  GET_SALES_DATA,
  GET_FINANCE_DATA,
  GET_PRODUCT_DATA,
  GET_CLIENT_EDIT_PROFILE_DETAIL,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_BUSINESSES,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_BUSINESSES_UM,
  GET_ADVISORS_ACCOUNT_DETAILS_UM,
  GET_TACKLE_MEETING_EVENTS,
  GET_USER_BUSINESS_PROFILE,
  GET_PLAY_ASSESSMENT_RESPONSE,
  GET_ASSESSMENT_PLAY_LISTING,
  GET_DASHBOARD_LISTING,
  GET_TOOLS_BY_TYPE,
  GET_USER_AVAILABILITY_HOURS,
  GET_ATTESTATION_SUMMARY,
  GET_ADVISORS_DASHBOARD,
  GET_ASSESSMENT_QUESTION,
  SAVE_PLAY_DATA,
  GET_ASSESSMENT_OPTIONS,
  PUBLISH_ANNOUNCEMENT_ACTION,
  GET_CLIENTS,
  GET_PLAY_RESPONSE,
  SAVE_PLAY_RESPONSE,
  GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS,
} from 'store/types'

const initialState = {
  bigQuery: false,
  recommendations: false,
  clientRecommendations: false,
  clientsSummary: false,
  myToolsLoading: false,
  allToolsLoading: false,
  upcomingEventLoading: false,
  isToolsByTypeLoading: false,
  clientDetailsLoading: false,
  clientBusinessProfileLoading: false,
  salesDataLoading: false,
  financeDataLoading: false,
  productDataLoading: false,
  detailsLoading: false,
  scoreCardLoading: false,
  clientActivityLogLoading: false,
  clientConnectedToolsLoading: false,
  isOwnerDataLoading: true,
  advisorDataLoading: true,
  isOwnerDataLoadingUM: true,
  advisorDataLoadingUM: true,
  meetingDataLoading: false,
  businessProfileLoading: false,
  isPlayLoading: false,
  getAssessmentPlayListingLoading: false,
  isAssessmentPlayLoading: false,
  isAvailabilityHoursLoading: false,
  isAttestationSummaryLoading: false,
  isAdvisorDashboardLoading: false,
  isAssessmentQuestionLoading: false,
  isPlayDataSaving: false,
  isAssessmentOptionsLoading: false,
  isFetchingResponse: false,
  isAllClientFetching: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED:
      return {
        ...state,
        recommendations: true,
      }
    case GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED:
      return {
        ...state,
        clientRecommendations: true,
      }
    case PUBLISH_ANNOUNCEMENT_ACTION.STARTED:
      return {
        ...state,
        isFetchingResponse: true,
      }
    case PUBLISH_ANNOUNCEMENT_ACTION.FULLFILLED:
      return {
        ...state,
        isFetchingResponse: false,
      }
    case PUBLISH_ANNOUNCEMENT_ACTION.REJECTED:
      return {
        ...state,
        isFetchingResponse: false,
      }
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED:
      return {
        ...state,
        recommendations: false,
      }
    case GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED:
      return {
        ...state,
        clientRecommendations: false,
      }
    case CLIENTS_SUMMARY.STARTED:
      return {
        ...state,
        clientsSummary: true,
      }
    case CLIENTS_SUMMARY.FULLFILLED:
      return {
        ...state,
        clientsSummary: false,
      }
    case GET_MY_TOOLS.STARTED:
      return {
        ...state,
        myToolsLoading: true,
      }
    case GET_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        myToolsLoading: false,
      }
    case GET_ALL_TOOLS.STARTED:
      return {
        ...state,
        allToolsLoading: true,
      }
    case GET_ALL_TOOLS.FULLFILLED:
      return {
        ...state,
        allToolsLoading: false,
      }
    case GET_TOOLS_BY_TYPE.STARTED:
      return {
        ...state,
        isToolsByTypeLoading: true,
      }
    case GET_TOOLS_BY_TYPE.FULLFILLED:
      return {
        ...state,
        isToolsByTypeLoading: false,
      }

    case GET_USER_CALENDAR_EVENTS.STARTED:
      return {
        ...state,
        upcomingEventLoading: true,
      }
    case GET_USER_CALENDAR_EVENTS.FULLFILLED:
      return {
        ...state,
        upcomingEventLoading: false,
      }
    case GET_CLIENT_PROFILE.STARTED:
      return {
        ...state,
        clientDetailsLoading: true,
      }
    case GET_CLIENT_PROFILE.FULLFILLED:
      return {
        ...state,
        clientDetailsLoading: false,
      }
    case GET_CLIENT_PROFILE_DETAILS.STARTED:
      return {
        ...state,
        clientBusinessProfileLoading: true,
      }
    case GET_CLIENT_PROFILE_DETAILS.FULLFILLED:
      return {
        ...state,
        clientBusinessProfileLoading: false,
      }
    case GET_BIG_QUERY.STARTED:
      return {
        ...state,
        bigQuery: true,
      }
    case GET_BIG_QUERY.REJECTED:
    case GET_BIG_QUERY.FULLFILLED:
      return {
        ...state,
        bigQuery: false,
      }
    case GET_SALES_DATA.STARTED:
      return {
        ...state,
        salesDataLoading: true,
      }
    case GET_SALES_DATA.FULLFILLED:
      return {
        ...state,
        salesDataLoading: false,
      }
    case GET_FINANCE_DATA.STARTED:
      return {
        ...state,
        financeDataLoading: true,
      }
    case GET_FINANCE_DATA.FULLFILLED:
      return {
        ...state,
        financeDataLoading: false,
      }
    case GET_PRODUCT_DATA.STARTED:
      return {
        ...state,
        productDataLoading: true,
      }
    case GET_PRODUCT_DATA.FULLFILLED:
      return {
        ...state,
        productDataLoading: false,
      }
    case GET_CLIENT_EDIT_PROFILE_DETAIL.STARTED:
      return {
        ...state,
        detailsLoading: true,
      }
    case GET_CLIENT_EDIT_PROFILE_DETAIL.FULLFILLED:
      return {
        ...state,
        detailsLoading: false,
      }
    case GET_CLIENT_ACTIVITY_SCORE.STARTED:
      return {
        ...state,
        scoreCardLoading: true,
      }
    case GET_CLIENT_ACTIVITY_SCORE.FULLFILLED:
      return {
        ...state,
        scoreCardLoading: false,
      }
    case GET_CLIENT_ACTIVITY_LOGS.STARTED:
      return {
        ...state,
        clientActivityLogLoading: true,
      }
    case GET_CLIENT_ACTIVITY_LOGS.FULLFILLED:
      return {
        ...state,
        clientActivityLogLoading: false,
      }
    case GET_CLIENT_ASSESSMENT_LIST.STARTED:
      return {
        ...state,
        clientAssessmentLoading: true,
      }
    case GET_CLIENT_ASSESSMENT_LIST.FULLFILLED:
      return {
        ...state,
        clientAssessmentLoading: false,
      }
    case GET_CLIENT_CONNECTED_TOOLS.STARTED:
      return {
        ...state,
        clientConnectedToolsLoading: true,
      }
    case GET_CLIENT_CONNECTED_TOOLS.FULLFILLED:
      return {
        ...state,
        clientConnectedToolsLoading: false,
      }
    case GET_BUSINESSES.STARTED:
      return {
        ...state,
        isOwnerDataLoading: true,
      }
    case GET_BUSINESSES.FULLFILLED:
      return {
        ...state,
        isOwnerDataLoading: false,
      }
    case GET_BUSINESSES.REJECTED:
      return {
        ...state,
        isOwnerDataLoading: false,
      }
    case GET_BUSINESSES_UM.STARTED:
      return {
        ...state,
        isOwnerDataLoadingUM: true,
      }
    case GET_BUSINESSES_UM.FULLFILLED:
      return {
        ...state,
        isOwnerDataLoadingUM: false,
      }
    case GET_BUSINESSES_UM.REJECTED:
      return {
        ...state,
        isOwnerDataLoadingUM: false,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.STARTED:
      return {
        ...state,
        advisorDataLoading: true,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.FULLFILLED:
      return {
        ...state,
        advisorDataLoading: false,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.REJECTED:
      return {
        ...state,
        advisorDataLoading: false,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS_UM.STARTED:
      return {
        ...state,
        advisorDataLoadingUM: true,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS_UM.FULLFILLED:
      return {
        ...state,
        advisorDataLoadingUM: false,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS_UM.REJECTED:
      return {
        ...state,
        advisorDataLoadingUM: false,
      }
    case GET_TACKLE_MEETING_EVENTS.STARTED:
      return {
        ...state,
        meetingDataLoading: action.payload.showLoading ? false : true,
      }
    case GET_TACKLE_MEETING_EVENTS.FULLFILLED:
      return {
        ...state,
        meetingDataLoading: false,
      }
    case GET_USER_BUSINESS_PROFILE.STARTED:
      return {
        ...state,
        businessProfileLoading: true,
      }
    case GET_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        businessProfileLoading: false,
      }
    case GET_PLAY_ASSESSMENT_RESPONSE.STARTED:
      return {
        ...state,
        isPlayLoading: true,
      }
    case GET_PLAY_ASSESSMENT_RESPONSE.FULLFILLED:
      return {
        ...state,
        isPlayLoading: false,
      }
    case GET_PLAY_RESPONSE.STARTED:
      return {
        ...state,
        isPlayLoading: action?.payload?.getResponse ? true : false,
      }
    case GET_PLAY_RESPONSE.FULLFILLED:
      return {
        ...state,
        isPlayLoading: false,
      }
    case SAVE_PLAY_RESPONSE.STARTED:
      return {
        ...state,
        isPlayLoading: action.payload?.getResponse ? true : false,
      }

    case SAVE_PLAY_RESPONSE.FULLFILLED:
      return {
        ...state,
        isPlayLoading: false,
      }
    case GET_ASSESSMENT_PLAY_LISTING.STARTED:
      return {
        ...state,
        getAssessmentPlayListingLoading: true,
      }
    case GET_ASSESSMENT_PLAY_LISTING.FULLFILLED:
      return {
        ...state,
        getAssessmentPlayListingLoading: false,
      }
    case GET_DASHBOARD_LISTING.STARTED:
      return {
        ...state,
        isAssessmentPlayLoading: true,
      }
    case GET_DASHBOARD_LISTING.FULLFILLED:
      return {
        ...state,
        isAssessmentPlayLoading: false,
      }
    case GET_USER_AVAILABILITY_HOURS.STARTED:
      return {
        ...state,
        isAvailabilityHoursLoading: true,
      }
    case GET_USER_AVAILABILITY_HOURS.FULLFILLED:
      return {
        ...state,
        isAvailabilityHoursLoading: false,
      }
    case GET_USER_AVAILABILITY_HOURS.REJECTED:
      return {
        ...state,
        isAvailabilityHoursLoading: false,
      }
    case GET_ATTESTATION_SUMMARY.STARTED:
      return {
        ...state,
        isAttestationSummaryLoading: true,
      }
    case GET_ATTESTATION_SUMMARY.FULLFILLED:
      return {
        ...state,
        isAttestationSummaryLoading: false,
      }
    case GET_ATTESTATION_SUMMARY.REJECTED:
      return {
        ...state,
        isAttestationSummaryLoading: false,
      }
    case GET_ADVISORS_DASHBOARD.STARTED:
      return {
        ...state,
        isAdvisorDashboardLoading: true,
      }
    case GET_ADVISORS_DASHBOARD.FULLFILLED:
      return {
        ...state,
        isAdvisorDashboardLoading: false,
      }
    case GET_ASSESSMENT_QUESTION.STARTED:
      return {
        ...state,
        isAssessmentQuestionLoading: true,
      }
    case GET_ASSESSMENT_QUESTION.FULLFILLED:
      return {
        ...state,
        isAssessmentQuestionLoading: false,
      }
    case GET_ASSESSMENT_OPTIONS.STARTED:
      return {
        ...state,
        isAssessmentOptionsLoading: true,
      }
    case GET_ASSESSMENT_OPTIONS.FULLFILLED:
      return {
        ...state,
        isAssessmentOptionsLoading: false,
      }
    case SAVE_PLAY_DATA.STARTED:
      return {
        ...state,
        isPlayDataSaving: true,
      }
    case SAVE_PLAY_DATA.FULLFILLED:
      return {
        ...state,
        isPlayDataSaving: false,
      }
    case GET_CLIENTS.STARTED:
      return {
        ...state,
        isAllClientFetching: true,
      }
    case GET_CLIENTS.FULLFILLED:
      return {
        ...state,
        isAllClientFetching: false,
      }
    case GET_CLIENTS.REJECTED:
      return {
        ...state,
        isAllClientFetching: false,
      }
    default:
  }
  return state
}

export default reducer
