import { getAuthToken } from 'lib/auth'
import NProgress from 'nprogress'
import redirectTo from 'utils/redirectTo'
import { OperationName, QueryResponse } from '../types'
import { handleErrors } from 'utils/error-handling'
import { GraphQLError } from 'utils/error-handling/graphql-error'
import { store } from 'App'
NProgress.configure({ showSpinner: false })

export const API_URL = process.env.REACT_APP_API_URL!

type JsonErrors = GraphQLError
export type JsonData<D = any> = { data: D extends OperationName ? QueryResponse<D> : D }

class API {
  fetch = async <D = any, V = any>(query: string, variables: V, onError = handleErrors) => {
    NProgress.start()
    const authToken = await getAuthToken()
    const res = await fetch(API_URL, {
      method: 'POST',
      headers: {
        ...(authToken && { Authorization: authToken }),
      },
      body: JSON.stringify({ query, variables }),
      keepalive: true,
      cache: 'no-cache',
    })
    if (res.status === 401) {
      if (window.location.href.includes('/auth/login')) return
      redirectTo('/auth/login', window.location.search)
    }
    const json = (await res.json()) as JsonData<D> & JsonErrors
    NProgress.done()
    if (json?.errors?.length) {
      const state = store.getState()
      const userId = state.user?.user?.id ?? ''
      onError(json.errors, { arguments: variables, operation: json.errors?.[0].path?.[0], userId })
    }
    return json?.data
  }
  uploadFile = async (url, formData, type) => {
    NProgress.start()
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': type || 'multipart/form-data',
      },
      body: formData,
    })

    if (res.status === 200) {
      NProgress.done()
      return res.url.split('?')[0]
    } else {
      NProgress.done()
      throw new Error('Failed to upload')
    }
  }
}

// eslint-disable-next-line
export default new API()
