import pluralize from 'pluralize'
import { getIsAllowCookies, saveUser } from 'utils/user'
import {
  ROLES,
  PLAY_STEPS,
  PLAY_INTEGRATION_QUERY_PARAMS,
  FORM,
  MEDIA_BUCKET,
  customLabels,
  OPTION_TYPE,
  ASSESSMENT_TYPE,
  INPUT_FIELD_OPTION_TYPE,
  USER_STATUS,
  VALUE_TYPE,
  USER_ROLES,
  ImpactReportData,
  RECOMMENDATION_CARD_TYPE,
  PLAY_ASSESSMENT_STATUS,
  ASSESSMENTS,
  PLAYBOOK_ASSESSMENTS,
  LABEL,
  supportedPlayIds,
  ACTIVE_STEP,
  ToolFlyOutOptions,
  TOOL_FLYOUT_OPTIONS,
} from 'config'
import { scroller } from 'react-scroll'
import history from 'utils/history'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import moment from 'moment'
import hookForms from 'utils/hookForms'
import set from 'lodash/set'
import { getMultipleRecommendations } from 'utils/assessments'
import { filter, includes, isEmpty, map, omit } from 'lodash'
import { ampli } from 'ampli'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { round } from 'javascript-time-ago/steps'
import { getExt } from 'components/Common/Dropzone/utils'
import isModalOpen from 'utils/isModalOpen'
import { openUsetifulTourIfNeeded } from 'lib/usetiful'
import { ZendeskSettings, ZendeskWidget } from 'zendesk-types'
import { REVENUE } from 'components/MonthlyAttestation/utils'
import { AttestationMinusIcon, AttestationPlusIcon } from 'components/Common/SvgIcons'
import { IUSER_NOTIFICATION_TYPES } from 'components/NotificationPanel/types'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { CLIENT_STATUS } from 'components/DataReports/ClientReportManagement/utils'
import {
  signedColumn,
  unSignedColumn,
} from 'components/DataReports/ClientReportManagement/ClientReportColumnData'
import { GroupedTools, Play, SortOrder, TabResponse, Tool } from '__generated__/api-types-and-hooks'
import {
  IAssessmentOptions,
  IAssessmentResponseObject,
  IColumnField,
  IPlayStage,
  IOption,
  IOptions,
  IResponse,
  IUser,
  IUserSummary,
  CustomFile,
  IPlayTab,
  extendedCalendarEvents,
  ExtendedTools,
} from 'types'
import { PlayBookCategoryParam } from 'components/Owner/PlayBooksCategoryDetails'
import redirectTo from './redirectTo'
import { ChannelMembership } from 'stream-chat'
import { updatePlayStageStepKeyWithStepResponse } from 'components/Owner/Plays/utils'
import { getTenantId } from './getTenantId'
import { parse } from 'querystring'

declare global {
  interface Window {
    zESettings?: ZendeskSettings
    zE?: ZendeskWidget
  }
}
interface ResponseItem {
  value: string
  id: string
}
interface OptionArray {
  industry: string
  label: string
  value: string
}
interface MeetingFormObject {
  attendees: string
  meetingTitle: string
  meetingLocation: string
}

export type Data = {
  fieldName: string
}
type FormData<T> = {
  [key: string]: T
}
interface meta {
  isPhoneNumber: boolean
  questionType: string
}
export type PlayData = Omit<Play, 'x' | 'meta'> & { recommendedBy: { id: string }; meta: meta }

export const findValueFromOptions = (id: string, options: OptionArray[]) => {
  if (options?.length > 0) {
    for (const option of options) {
      if (id === option?.value) {
        return option.label
      }
    }
  }
  return null
}
const findOrder = (column) => {
  let order: IColumnField | null = column.reduce((foundObject, item) => {
    const colFields = item.colFields
    const matchingField = colFields.find((colField) => !isEmpty(colField.sortingOrder))

    return foundObject || matchingField || null
  }, null as IColumnField | null)

  return order
}

export const getOrder = (filter) => {
  if (filter?.label === CLIENT_STATUS.SIGNED) {
    let order = findOrder(signedColumn)
    if (order?.sortingOrder === SortOrder.Ascending) return SortOrder.Descending
    return SortOrder.Ascending
  } else {
    let order = findOrder(unSignedColumn)
    if (order?.sortingOrder === SortOrder.Ascending) return SortOrder.Descending
    return SortOrder.Ascending
  }
}

export interface FormValuesObject {
  businessClassificationOptionId: string
}
type QuestionType = {
  isInput: boolean
  isNumeric: boolean
  isCurrency: boolean
  isMultiField: boolean
}

export const getAnswer = (questionType: QuestionType, answers, allowMultiSelect: boolean) => {
  return questionType.isInput || questionType.isNumeric || questionType.isCurrency
    ? [answers?.id]
    : questionType.isMultiField
    ? answers.id.map((answer) => answer.value)
    : allowMultiSelect
    ? map(answers, 'value')
    : [answers.value]
}

export const getBusinessLocationOptions = (formValues: FormValuesObject, options: IOptions) => {
  const businessClassification = findValueFromOptions(
    formValues.businessClassificationOptionId,
    options?.businessClassification
  )
  if (businessClassification)
    return options?.businessLocations?.filter((location) =>
      location?.industry?.includes(businessClassification?.toLowerCase())
    )
  return []
}

export const API_TYPE = (action: string) => ({
  STARTED: `${action}_STARTED`,
  FULLFILLED: `${action}_FULLFILLED`,
  REJECTED: `${action}_REJECTED`,
})

/** Get the last piece of a given URL path */
export const getFinalPathExtension = (path) => path?.split('/').reverse()?.[0] || ''

export const userInitialRouting = (userProfile, tenantId: string, next?: string) => {
  const isAllow = getIsAllowCookies()
  if (isAllow) {
    saveUser(userProfile)
  }
  if (get(userProfile, 'getMyProfile.roles[0]', '')) {
    const role = userProfile.getMyProfile.roles[0].toUpperCase()
    if (role === 'BUSINESS_OWNER' && get(userProfile, 'getMyProfile.isNewUser', false)) {
      redirectTo(`/owner/${tenantId}/assessment`)
    } else if (
      (role === 'BSO_ADVISOR' || role === 'BSO_ADMIN') &&
      get(userProfile, 'getMyProfile.isNewUser', false)
    ) {
      redirectTo(`/${ROLES[`${role}`]}/${tenantId}/onboarding/welcome-advisor`)
    } else {
      if (next?.includes(ROLES[role])) {
        redirectTo(next)
      } else {
        redirectTo(`/${ROLES[`${role}`]}/${tenantId}/dashboard`)
      }
    }
  } else {
    if (next) {
      redirectTo(next)
    } else {
      redirectTo('/auth/login')
    }
  }
}

// A function to replace keyword with it's replacement
export const replaceTerm = (
  inputTerm: string,
  terminology: { term: string; replacement: string }[],
  options?: { pluralized: boolean }
): string => {
  const bsoTerms = terminology || []
  const termInfo = bsoTerms?.find(
    (termObj) => termObj.term.toLowerCase() === inputTerm.toLowerCase()
  )
  const outputTerm = termInfo ? termInfo.replacement : inputTerm
  const pluralizedOutputTerm = pluralize(outputTerm)
  return options?.pluralized
    ? pluralizedOutputTerm.charAt(0).toUpperCase() + pluralizedOutputTerm.slice(1)
    : outputTerm.charAt(0).toUpperCase() + outputTerm.slice(1)
}

export const isActiveStep = (activeStep, title) => {
  return activeStep === title ? 'block' : 'hidden'
}

export const getRoleFromPath = (): string | undefined => {
  // Now instead of getting pathname from history i am getting it from window because storybook open in iframe and storybook don't have access to createBrowserHistory.
  const pathname = window.location.pathname.split('/')
  return pathname?.[1]
}

export const getUserRole = (): string => {
  const userRole = getRoleFromPath()
  return userRole === ROLES.BUSINESS_OWNER ? USER_ROLES.BUSINESS_OWNER : USER_ROLES.BSO_ADVISOR
}

export const getCurrentRoute = () => {
  return `${window.location.pathname}${window.location.search}`
}

export const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

export const handleSelectedTab = (
  tabName,
  userRole,
  path,
  tenantId = '',
  isSelectedTab = '',
  openNotifications = false
) => {
  if (openNotifications && tabName.toLowerCase() === 'bell') return true
  if (isSelectedTab) {
    path = path.replace(`/${isSelectedTab}`, '')
  }
  return path === `/${userRole}/${tenantId}/${tabName}`
}

export const copy = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const getAssessmentResponse = ({ keys, assessment_questions }) => {
  const assessmentResponse: any = []
  Object.keys(keys).forEach((key, index) => {
    if (keys[key]) {
      // find recommendation object is available in selected option of assestion question
      let activeQuestion = assessment_questions.find((question) => question.id === key)
      let answer
      answer = activeQuestion?.options.find((option_id) => option_id.id === keys[key])

      if (activeQuestion?.meta?.allowMultiSelect) {
        answer = filter(activeQuestion?.options, (op) => includes(keys[key], op.id))
      }

      assessmentResponse.push({
        id: key,
        value: keys[key],
        isTopGoal: activeQuestion?.meta.isTopGoal || undefined,
        recommendations: activeQuestion?.meta?.allowMultiSelect
          ? getMultipleRecommendations(answer)
          : answer?.action?.recommendations || undefined,
        isIndustry: activeQuestion?.meta?.isIndustry || undefined,
        isSubIndustry: activeQuestion?.meta?.isSubIndustry || undefined,
      })
    }
  })

  return assessmentResponse
}

export const getLabel = (userProfile) => {
  return userProfile && userProfile?.status === USER_STATUS.ACTIVE
    ? USER_STATUS.DISABLE_USER
    : userProfile?.status === USER_STATUS.DISABLED
    ? USER_STATUS.ENABLE_USER
    : USER_STATUS.NA
}

export const getActiveStepAndNavigationLink = ({ playStage, stepName, id }) => {
  let activeStep = ''
  let link = ''
  let activeStepIndex = 0
  if (!playStage?.guide?.isCompleted) {
    activeStep = PLAY_STEPS.GUIDE
    link = getLink(id, PLAY_STEPS.GUIDE)
  } else if (!playStage?.setUp?.isCompleted) {
    activeStep = PLAY_STEPS.SETUP
    link = getLink(id, PLAY_STEPS.SETUP)

    playStage?.setUp?.steps?.every((step, index) => {
      if (!step.isCompleted) {
        activeStepIndex = index
        return false
      }
      return true
    })
  } else if (!playStage?.use?.isCompleted) {
    activeStep = PLAY_STEPS.USE
    link = getLink(id, PLAY_STEPS.USE)
  } else if (
    (playStage?.use?.isCompleted && !playStage.isCompleted) ||
    (playStage && playStage.isCompleted)
  ) {
    activeStep = PLAY_STEPS.GUIDE
    link = getLink(id, PLAY_STEPS.GUIDE)
  }

  if (history.location.search.includes(PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS)) {
    link = link + PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS
  }

  return {
    activeStep,
    link,
    activeStepIndex,
  }
}

export const getPlayStageAndAssessmentResponse = ({ assessments, play }) => {
  let playStage
  if (assessments) {
    playStage = JSON.parse(assessments?.playStage)

    assessments.assessmentResponse = assessments.assessmentResponse || []
  }

  if (!playStage && !play.playTabs) {
    // get play steps
    let getPlaySteps = play?.setup?.steps?.map((step) => {
      return {
        isCompleted: false,
        id: step?.id,
        icon: step?.icon,
        title: step?.title,
      }
    })

    // create play-state
    playStage = {
      isCompleted: false,
      guide: {
        isCompleted: false,
      },
      setUp: {
        isCompleted: false,
        steps: getPlaySteps,
      },
      use: {
        isCompleted: false,
      },
    }
  } else if (!playStage && play.playTabs) {
    playStage = []
    for (let i = 0; i < play.playTabs.length; i++) {
      for (let j = 0; j < play.playTabs[i].tabData.steps.length; j++) {
        set(playStage, `[${i}].steps[${j}].isCompleted`, false)
      }
    }
  }
  return { playStage, assessmentResponse: assessments }
}

export const getPlayStepResponse = (playTabs: IPlayTab[], playResponse: TabResponse[]) => {
  let playSteps: IPlayStage[] = []

  for (let i = 0; i < playTabs?.length; i++) {
    for (let j = 0; j < playTabs[i].tabData.steps.length; j++) {
      for (let k = 0; k < playTabs[i].tabData.steps[j].stepData.length; k++) {
        set(playSteps, `[${i}].isCompleted`, false)
        set(playSteps, `[${i}].id`, playTabs[i]?.id)
        set(playSteps, `[${i}].stepResponse[${j}].isCompleted`, false)
        set(playSteps, `[${i}].stepResponse[${j}].stepData[${k}].isCompleted`, false)
      }
    }
  }

  if (playResponse?.length > 0) {
    playSteps = updatePlayStepWithPlayResponse(playResponse, playSteps)
  }

  playSteps = updatePlayStageStepKeyWithStepResponse(playSteps, playResponse, true)

  return { playSteps }
}
const updatePlayStepWithPlayResponse = (data: TabResponse[], playStep: IPlayStage[]) => {
  let playDataResponse = copy(data)
  playDataResponse?.forEach((response) => {
    let foundIndex = playStep.findIndex((data) => data?.id === response?.id)
    if (foundIndex) {
      playStep[foundIndex] = response
    }
  })
  return playStep
}
export const launchUsetifulIfNeeded = async () => {
  if (!(await isModalOpen())) {
    openUsetifulTourIfNeeded()
  }
}

export const groupToolsByCategoryHelper = ({
  tools,
  filterEmptyCategories,
}: {
  tools: ExtendedTools[]
  filterEmptyCategories?: boolean
}) => {
  const categories = ['Business Essentials', 'Finance', 'Sales', 'Operations']
  const groupedTools: { [category: string]: Tool[] } = {}

  tools?.forEach((tool) => {
    const { category } = tool
    if (!groupedTools[category!]) {
      groupedTools[category!] = []
    }
    groupedTools[category!].push(tool)

    tool = getToolFlyoutOptions(tool)
  })

  // Add other categories for empty state display
  if (!filterEmptyCategories) {
    categories.forEach((category) => {
      if (!groupedTools[category]) {
        groupedTools[category] = []
      }
    })
  }

  // Sort categories in alphabetical order
  const sortedCategories: GroupedTools[] = Object.entries(groupedTools)
    .sort(([categoryA], [categoryB]) => categoryA.localeCompare(categoryB))
    .map(([category, toolsArr]) => ({
      category,
      tools: toolsArr.sort((a, b) => a.toolName!.localeCompare(b.toolName!)),
    }))

  // Filter out categories without at least one visible tool
  const filteredCategories = sortedCategories.filter((category) =>
    category.tools?.some((tool) => tool?.visible === true)
  )

  return filterEmptyCategories ? filteredCategories : sortedCategories
}

export const getToolFlyoutOptions = (tool: ExtendedTools) => {
  tool.toolFlyoutOptions = ToolFlyOutOptions.map(({ ...option }) => {
    if (option.name === TOOL_FLYOUT_OPTIONS.PLAYS) {
      if (!tool.relatedPlays || tool.relatedPlays.length === 0) {
        option.isVisible = false
      }
    } else if (option.name === TOOL_FLYOUT_OPTIONS.DIAGNOSTICS) {
      if (!tool.diagnosticSupported) {
        option.isVisible = false
      }
    } else if (option.name === TOOL_FLYOUT_OPTIONS.LEARN_MORE) {
      if (!tool?.longDescription) {
        option.isVisible = false
      }
    }
    return option
  })
  tool.toolFlyoutOptions = tool.toolFlyoutOptions.filter((option) => option.isVisible)
  return tool || []
}
export const sortHandlerHelper = ({ sortField, sortOrder, fieldType, data }) => {
  try {
    if (data?.length === 0) {
      return []
    }

    let sortedData = [...data]
    if (sortField !== null) {
      sortedData.sort((a, b) => {
        const fieldA = String(a[sortField]).trim() // Trim leading and trailing spaces
        const fieldB = String(b[sortField]).trim() // Trim leading and trailing spaces

        // Check if the fields are empty strings
        const fieldAIsEmpty = fieldA === ''
        const fieldBIsEmpty = fieldB === ''

        if (fieldAIsEmpty && !fieldBIsEmpty) return -1
        if (fieldBIsEmpty && !fieldAIsEmpty) return 1

        if (fieldType === 'date') {
          let dateA = new Date(fieldA)
          let dateB = new Date(fieldB)
          // @ts-ignore - JS knows how to compare dates despite TS warnings
          return sortOrder === SortOrder.Ascending ? dateA - dateB : dateB - dateA
        } else {
          // Convert both fieldA and fieldB to lowercase for case-insensitive comparison
          const lowerFieldA = fieldA.toLowerCase()
          const lowerFieldB = fieldB.toLowerCase()

          if (lowerFieldA < lowerFieldB) {
            return sortOrder === SortOrder.Ascending ? -1 : 1
          }
          if (lowerFieldA > lowerFieldB) {
            return sortOrder === SortOrder.Ascending ? 1 : -1
          }
        }

        return 0
      })
    }
    return sortedData
  } catch (err: any) {
    console.log('error: sortHandlerHelper: ', err.message)
    return []
  }
}

export const dateFormatterHelper = (date) => {
  try {
    let updatedDate = ''

    if (!date) {
      return ''
    }

    const currentDate: number = new Date(moment().format('YYYY-MM-DD')).getTime()
    const comingData: number = new Date(moment(date).format('YYYY-MM-DD')).getTime()

    const diffTime = Math.abs(currentDate - comingData)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) {
      updatedDate = 'Today'
    } else if (diffDays === 1) {
      updatedDate = 'Yesterday'
    } else {
      updatedDate = moment(date).format('LL')
    }

    return updatedDate
  } catch (err) {
    console.log(err)
    return ''
  }
}

export const isImgLink = (url: string) => {
  if (typeof url !== 'string') return false
  return url.match(/^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/) != null
}

export const getFormData = () => {
  const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
  const BusinessForm = hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM)

  const otherBusinessForm = hookForms.getForm(FORM.BUSINESS_OTHER_DETAILS)

  const userProfileFormData = userProfileForm?.getValues()

  const BusinessFormData = BusinessForm?.getValues()

  const otherBusinessFormData = otherBusinessForm?.getValues()
  const data = { ...userProfileFormData, ...BusinessFormData, ...otherBusinessFormData }
  return data
}

/** Takes a raw number and returns a string version formatted with commas every 3 digis (in U.S. fashion) */
export const formatNumber = (val: number) => {
  if (val) return val.toLocaleString('en-US')
  return val
}

export const timeDuration = (data: string) => {
  const customStyle = {
    steps: round,
    labels: 'custom',
  }
  TimeAgo.addLabels('en', 'custom', customLabels)
  TimeAgo.addLocale(en)

  const timeAgo = new TimeAgo('en-US')

  let timeInMilliseconds = moment(data).valueOf()
  let time = timeAgo.format(timeInMilliseconds - 1 * 1000, customStyle)
  if (time.includes('minute')) {
    time = time.replace('minutes', 'min').replace('minute', 'min')
  }

  return time
}

export const isSameObject = (data, orgData) => {
  const diffVal = find(data, (value, key) => {
    const refValue = get(orgData, key)
    if ((value || refValue) && !isEqual(value, refValue)) {
      return true
    }
    return false
  })
  return isNil(diffVal)
}

export const openNewWindow = (url, isLaunchType) => {
  const h = isLaunchType ? window.outerWidth : window.outerWidth / 2
  const w = isLaunchType ? window.outerHeight : window.outerHeight / 2
  const y = window.outerHeight / 2 + window.screenY - h / 2
  const x = window.outerWidth / 2 + window.screenX - w / 2

  return window.open(
    url,
    'newWindow',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyHistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
}

export const onLaunchTool = (tool: any) => {
  ampli.toolLaunched({ id: tool.id, name: tool.toolName })
  const toolWindowHandle = openNewWindow(tool.toolUrl, true)
  if (!toolWindowHandle) return
  var timer = setInterval(function () {
    if (toolWindowHandle.closed) {
      clearInterval(timer)
      ampli.toolClosed({ id: tool.id, name: tool.toolName })
    }
  }, 1000)
  return timer
}

export const getImageUrl = (url) => {
  return `${MEDIA_BUCKET}/${url}`
}

export const scrollToSpecificAssessment = (list, openAssessment, tenantId) => {
  scroller.scrollTo(`assessment-${list.assessmentType}`, {
    duration: 1500,
    delay: 100,
    smooth: true,
    containerId: 'containerElement',
    offset: -80,
  })
  if (openAssessment) openAssessment()
  if (get(list, 'type', '') === 'play') history.push(`/owner/${tenantId}/plays/${list.id}/guide`)
}

export const checkIndustry = (
  question,
  profileFormValues,
  subIndustryQuestion,
  foodIndustryQuestionOption,
  industryQuestion,
  servicesSubIndustryQuestion,
  serviceIndustryQuestionOption
) => {
  if (
    (profileFormValues &&
      subIndustryQuestion &&
      question?.id === subIndustryQuestion?.id &&
      profileFormValues[industryQuestion.id] === foodIndustryQuestionOption?.id) ||
    (profileFormValues &&
      servicesSubIndustryQuestion &&
      question?.id === servicesSubIndustryQuestion?.id &&
      profileFormValues[industryQuestion.id] === serviceIndustryQuestionOption?.id)
  ) {
    return true
  } else if (
    (servicesSubIndustryQuestion && question?.id === servicesSubIndustryQuestion?.id) ||
    (subIndustryQuestion && question?.id === subIndustryQuestion?.id)
  ) {
    return false
  } else {
    return true
  }
}
export const isQuestionHaveSubIndustry = (
  profileFormValues,
  foodIndustryQuestionOption,
  serviceIndustryQuestionOption,
  industryQuestion
) => {
  if (
    profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id ||
    profileFormValues[industryQuestion?.id] === serviceIndustryQuestionOption?.id
  ) {
    return true
  } else {
    return false
  }
}

export const checkFormData = (timeSlots) => {
  let isFormDataValid = true

  for (let i = 0; i < timeSlots?.length; i++) {
    if (timeSlots[i].checked) {
      for (let j = 0; j < timeSlots[i]?.slots.length; j++) {
        if (
          isEmpty(timeSlots[i]?.slots[j]?.startTime) ||
          isEmpty(timeSlots[i]?.slots[j]?.endTime)
        ) {
          isFormDataValid = false
          break
        } else {
          isFormDataValid = true
        }
      }
    }
    if (!isFormDataValid) break
  }
  let isValid = timeSlots?.every((slot) => !slot.checked)
  if (isValid) {
    isFormDataValid = false
  }

  return isFormDataValid
}

export const createTimeSlot = (startingTime: string, endingTime: string, timeIncrement: number) => {
  let startTimeMoment = moment(startingTime, 'hh:mm A')
  let startingTimeTwelveHour: any = startingTime

  const endTimeMoment = moment(endingTime, 'hh:mm A')

  if (endTimeMoment.isSameOrBefore(startTimeMoment)) {
    endTimeMoment.add(1, 'day')
  }

  let startTimeTwentyFourHour = moment(startTimeMoment).format('HH:mm')
  const timeSlotsArray: { label: string; value: string }[] = []
  while (startTimeMoment.isSameOrBefore(endTimeMoment)) {
    timeSlotsArray.push({ label: startingTimeTwelveHour, value: startTimeTwentyFourHour })
    startingTimeTwelveHour = startingTime
    startTimeMoment = startTimeMoment.add(timeIncrement, 'm')
    startTimeTwentyFourHour = moment(startTimeMoment).format('HH:mm')

    startingTimeTwelveHour = startTimeMoment.format('hh:mm A')
  }
  return timeSlotsArray
}
export const removeEmptySlots = (timeSlots) => {
  let updatedTimeSlots = timeSlots

  updatedTimeSlots.forEach((obj) => {
    obj.slots = obj.slots.filter((item) => item.startTime !== '')
  })

  updatedTimeSlots = updatedTimeSlots.map((slot) => {
    return omit(slot, ['checked'])
  })
  return updatedTimeSlots
}

export const createResponse = (data: Data[], formData: FormData<string>, playData: PlayData) => {
  let response = { playResponse: {}, id: '' }
  data.forEach((item) => {
    const fieldName = item.fieldName

    if (formData.hasOwnProperty(fieldName)) {
      response[fieldName] = true
    }
  })
  response.id = playData?.recommendedBy?.id
  response.playResponse = JSON.stringify({ [`${playData.id}`]: { value: formData } })
  response[playData.meta.questionType] = true
  return response
}
export const formatFileNameWithExtension = (fileName: string) => {
  if (fileName?.length <= 16) {
    return fileName
  } else {
    return fileName?.substring(0, 16) + '...' + getExt(fileName)
  }
}
export const checkError = (regex, selectedOption, isSubmitted) => {
  return (
    (regex && selectedOption && !selectedOption.match(regex)) ||
    (isSubmitted && isEmpty(selectedOption))
  )
}
export const getErrorMessage = (label) => `Please enter a valid ${label?.toLowerCase()}`

export const checkTimeSlots = (parentSlots, timeSlotsCreation, childIndex) => {
  let isFound = false
  for (let i = 1; i < timeSlotsCreation.length - 1; i++) {
    const valueToCheck = timeSlotsCreation[i].value

    for (let j = 0; j < parentSlots.length; j++) {
      if (j !== childIndex) {
        if (valueToCheck === parentSlots[j].startTime || valueToCheck === parentSlots[j].endTime) {
          isFound = true
          break
        }
      }
    }
  }

  return isFound
}
export const formatSlotTime = (time) => {
  const formattedTime = moment(time, 'HH:mm').format('h:mm A')
  return formattedTime
}

export const isDisabled = (level, assessmentsListing, type) => {
  if (assessmentsListing.length > 0) {
    if (level === 'Essentials') {
      const assessment = assessmentsListing.find(
        (a) =>
          a.assessmentType.startsWith(type) && a.assessmentType[a.assessmentType.length - 1] === '1'
      )

      if (assessment && !assessment?.isLocked) {
        return false
      } else {
        return true
      }
    }
    // TODO: Uncomment this once we have a clear set of rules around when intermediate plays should be shown.
    // } else if (level === 'Intermediate') {
    //   const assessment = assessmentsListing.find(
    //     (a) =>
    //       a.assessmentType.startsWith(type) && a.assessmentType[a.assessmentType.length - 1] === '2'
    //   )
    //   if (assessment && !assessment?.isLocked) {
    //     return false
    //   } else {
    //     return true
    //   }
    // } else if (level === 'Advanced') {
    //   const assessment = assessmentsListing.find(
    //     (a) =>
    //       a.assessmentType.startsWith(type) && a.assessmentType[a.assessmentType.length - 1] === '3'
    //   )
    //   if (assessment && !assessment?.isLocked) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
    // return false
  }
  return true
}
export const showToast = (toastData, currentUser, closeToast) => {
  return (
    toastData?.variant &&
    currentUser?.attestationStatus === 'UNSIGNED' &&
    !closeToast &&
    !window.location.href.includes('?event=signing_complete')
  )
}
export const checkAvailability = (date, userAvailabilityList) => {
  const dateString = moment(date).utc().format('YYYY-MM-DD')
  const checkDate = userAvailabilityList.availability.find((item) => item.date === dateString)
  return checkDate ? true : false
}

export const formatValue = (value) => {
  const sanitizedValue = value.replace(/,/g, '')
  const isValid = /^\d*$/.test(sanitizedValue)
  let formattedValue = ''

  if (isValid && sanitizedValue !== '') {
    formattedValue = parseInt(sanitizedValue, 10).toLocaleString()
  } else {
    formattedValue = value.slice(0, -1)
  }

  return { value: formattedValue }
}

export const navigateTo = (
  role: string | undefined,
  path: string,
  isHistoryReplaced?: boolean,
  step?: string
) => {
  if (isHistoryReplaced) {
    history.replace(`/${role}/${getTenantId()}/${path}/${step}`)
  } else {
    history.push(`/${role}/${getTenantId()}/${path}`)
  }
}

//this is function for toggle help desk
export const helpDesk = () => {
  if (window.zE) window.zE('messenger', 'open')
}
export const convertStringToNumber = (value) => {
  return value?.replace('$', '').replace(',', '')
}

export const getIcon = (financialGrowthCheck: string) => {
  if (financialGrowthCheck === REVENUE.PROFIT) {
    return AttestationPlusIcon
  } else if (financialGrowthCheck === REVENUE.LOSS) {
    return AttestationMinusIcon
  }
  return null
}
export const getLink = (id: string, playStep: string) => {
  const link = `/${ROLES.BUSINESS_OWNER}/${getTenantId()}/plays/${id}/${playStep}${
    window.location.search
  }`
  return link
}

export const replaceState = (title: string) => {
  window.history.replaceState(null, title, `/${getRoleFromPath()}/${getTenantId()}/chat`)
}

export const goToNextPath = (item) => {
  const next = item?.path?.replace?.(':tenantId', getTenantId()) || ''
  if (next) history.push(next)
}

export const formatCurrency = (value, currencyType = 'USD') => {
  const numericValue = parseFloat(value)
  if (isNaN(numericValue)) return
  return numericValue.toLocaleString('en-US', {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 0,
  })
}
export const viewNotificationDetails = (notification, handleClose) => {
  if (notification.url) {
    history.push(`/${notification?.url}`)
    handleClose()
  }
}
export const viewMeetingDetail = (notification, handleClose) => {
  if (notification.meta.url) {
    history.push(`/${notification?.meta.url}`)
    handleClose()
  }
}
export const updateAmplitudeLog = (name, businessName, notification) => {
  if (notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_TIME) {
    ampli.monthlyImpactReportViewedFromNotifications({
      userName: name,
      businessName,
      notificationContent: notification.meta.text,
    })
  }
}
export const sortList = (list) => {
  list.sort((a, b) =>
    (a.label.trim().toLowerCase() || '').localeCompare(b.label.trim().toLowerCase() || '')
  )
}

export function replaceAll(str: string, find: RegExp, replace: string) {
  return str.replace(new RegExp(find), replace)
}

export const onSelectActionsOptions = (
  value,
  userData,
  openConfirmationModalAction,
  setOpenResetPasswordModal
) => {
  if (value === 'resetpassword') {
    setOpenResetPasswordModal(true)
  } else if (value === 'chat') {
    navigateTo(ROLES.BSO_ADMIN, `chat/${userData.userId}`)
  } else if (value === 'resendinvitation') {
    openConfirmationModalAction({
      openConfirmationModal: true,
      email: userData.email,
    })
  }
}
export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      return false
    }
  }
  return true
}

export const checkIsLoading = (isLoading, data, isDataExist) => {
  return isLoading || (data.length < 1 && isDataExist)
}

export const getMultiFieldResponse = (question, businessProfile) => {
  const responseArray: ResponseItem[] = []
  for (let option of question.options) {
    let data = {
      value: businessProfile[option?.meta?.schema],
      id: option.id,
      label: option.label,
    }
    responseArray.push(data)
  }
  return responseArray
}

export const fillAssessmentData = (
  assessmentResponse,
  assessmentQuestions,
  businessProfile,
  assessmentType
) => {
  if (
    assessmentType === ASSESSMENT_TYPE.INITIAL ||
    assessmentType === ASSESSMENT_TYPE.BASELINE ||
    assessmentType.includes(ASSESSMENT_TYPE.BUSINESS_ESSENTIALS) ||
    assessmentType.includes(ASSESSMENT_TYPE.FINANCE_ESSENTIALS)
  ) {
    for (const question of assessmentQuestions) {
      let businessProfileAnswer
      const responseFound = assessmentResponse?.find((response) => question?.id === response?.id)

      if (isEmpty(responseFound)) {
        //this will find response in business Profile
        businessProfileAnswer = checkOptionTypeIsInput(question.optionType)
          ? businessProfile[`${question?.meta?.schema}`]
          : businessProfile[`${question?.meta?.schema}OptionId`] ||
            businessProfile[`${question?.meta?.schema}OptionIds`]

        let matchFound
        let data
        if (question.optionType === OPTION_TYPE.MULTI_FIELD) {
          matchFound = getMultiFieldResponse(question, businessProfile)
        } else if (question?.meta?.schema && checkOptionTypeIsInput(question.optionType)) {
          matchFound = businessProfileAnswer
        } else {
          matchFound = businessProfileAnswer
        }

        if (!isEmpty(matchFound)) {
          data = {
            id: question.id,
            value: matchFound,
          }
          assessmentResponse.push(data)
        }
      }
    }
  }
  return assessmentResponse
}

export const updateAssessmentOptionsWithOptionTable = (assessmentQuestions, optionsData) => {
  let modifiedAssessmentQuestions = assessmentQuestions
  modifiedAssessmentQuestions.forEach((assessment) => {
    assessment.options.forEach((option) => {
      const matchingOption = optionsData?.find((resOption) => resOption.id === option?.id)
      if (matchingOption) {
        if (option.value !== 'Submit') {
          option.value = matchingOption.name
        }
        option.toolTipText = matchingOption?.toolTipText
        option.infoText = matchingOption?.infoText
        option.label = matchingOption?.label || option.label
      }
    })
  })
  return modifiedAssessmentQuestions
}
export const fillAssessmentWithUpdatedOptions = (
  questions,
  assessment,
  options,
  businessProfile,
  assessmentType
) => {
  let assessmentQuestions = updateAssessmentOptionsWithOptionTable(assessment, options)
  let responses = copy(questions.assessmentResponse)
  return fillAssessmentData(responses, assessmentQuestions, businessProfile, assessmentType)
}
export const checkOptionTypeIsInput = (option) => {
  return Object.values(INPUT_FIELD_OPTION_TYPE).includes(option)
}
interface Object {
  registerBusinessStructureOptionId: string
  registerBusinessStructure: string
}
export const getRegisteredBusinessStructureLabel = (object: Object, options: OptionArray[]) => {
  if (Object?.keys(object)?.includes(ImpactReportData.businessStructure)) {
    let foundAnswer = options.find(
      (option) => object[`${ImpactReportData.businessStructure}`] === option.value
    )
    object[`${ImpactReportData.businessStructure}OptionId`] =
      object[`${ImpactReportData.businessStructure}`]
    object[`${ImpactReportData.businessStructure}`] = foundAnswer?.label
  }
  return object
}

export const convertStringToNumbers = (object) => {
  const result = {}

  for (const key in object) {
    const value = object[key]
    if (typeof value === VALUE_TYPE.STRING && /[0-9]/.test(value)) {
      const numericValue = Number(value.replace(/[$,]/g, ''))
      result[key] = numericValue
    } else {
      result[key] = value
    }
  }

  return result
}

export const excludeKeysFromObject = (object, excludeKeys) => {
  const newObj = {}
  for (const key in object) {
    if (!excludeKeys.includes(key)) {
      newObj[key] = object[key]
    }
  }

  return newObj
}

export const mapValuesForAmpli = (data, changedValues) => {
  let newData = {}
  for (const key in data) {
    if (data.hasOwnProperty(key) && changedValues.hasOwnProperty(data[key])) {
      newData[key] = changedValues[data[key]]
    }
  }
  return newData
}

export const setActiveTabFromUrl = (url) => {
  url = url.split('/')
  const activeTab = url[url.length - 1]
  return activeTab
}

export const requestMeetingLogsFields = (
  participantName: string,
  clientBusinessName: string,
  organizationName: string
) => {
  const role = getRoleFromPath()
  let ampliProperties = {}
  if (role === ROLES.BUSINESS_OWNER) {
    ampliProperties = {
      advisorName: participantName,
      bsoOrgName: organizationName,
    }
  } else {
    ampliProperties = {
      clientName: participantName,
      clientBusinessName: clientBusinessName,
    }
  }
  return ampliProperties
}
interface formValues {
  location: string
  title: string
  guests: string
  additionalDetails: string
}
export const meetingFormChangedValues = (
  meetingForm: MeetingFormObject,
  event: extendedCalendarEvents,
  description: EditorState
) => {
  let changedValues: formValues = {
    location: '',
    title: '',
    guests: '',
    additionalDetails: '',
  }
  if (meetingForm.meetingLocation !== event.location) {
    changedValues.location = meetingForm.meetingLocation
  }

  if (meetingForm.meetingTitle !== event.title) {
    changedValues.title = meetingForm.meetingTitle
  }

  if (meetingForm.attendees !== event.attendeesEmail.join(', ')) {
    changedValues.guests = meetingForm.attendees
  }
  if (draftToHtml(convertToRaw(description.getCurrentContent())) !== event.description) {
    changedValues.additionalDetails = description.getCurrentContent().getPlainText()
  }

  return changedValues
}
export const getMonthName = (index) => {
  const normalizedIndex = Math.min(11, Math.max(0, index))
  const monthName = moment().month(normalizedIndex).format('MMM')
  return monthName
}

export const getFormattedNumber = (mobileContactNumber: string) => {
  return mobileContactNumber?.length > 0
    ? mobileContactNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    : ''
}

export const trackAmpliEventForAssessmentAndPlay = (
  user: IUser,
  id: string,
  variant: string,
  viewedContentFrom: string,
  title: string = '',
  category: string = '',
  level: string = '',
  status: string = ''
) => {
  if (status === PLAY_ASSESSMENT_STATUS.COMPLETED) {
    if (variant === RECOMMENDATION_CARD_TYPE.ASSESSMENT) {
      ampli.completedAssessmentViewedByBo({
        userName: user.userName,
        businessName: user?.businessName,
        industry: user.industry,
        assessmentName: ASSESSMENTS[id]?.title,
      })
    }
  } else if (variant === RECOMMENDATION_CARD_TYPE.ASSESSMENT) {
    ampli.recommendedAssessmentViewedByBo({
      userName: user.userName,
      businessName: user.businessName,
      industry: user.industry,
      openedAssessmentFrom: viewedContentFrom,
      assessmentName: ASSESSMENTS[id]?.title,
    })
  } else if (variant === RECOMMENDATION_CARD_TYPE.PLAY) {
    ampli.recommendedPlayViewedByBo({
      userName: user.userName,
      businessName: user.businessName,
      industry: user.industry,
      openedPlayFrom: viewedContentFrom,
      playName: title,
      category,
      level,
    })
  }
}

export const findValueFromResponse = (
  assessmentResponse: IAssessmentResponseObject,
  assessmentOptions: IAssessmentOptions,
  attribute: string,
  value: string
) => {
  for (const response of assessmentResponse.assessmentResponse) {
    const matchingOption = assessmentOptions[attribute]?.find((option) => {
      return option.value === response.value
    })

    if (matchingOption) {
      return matchingOption.label
    }
  }

  return value
}
export const extractAssessmentSubString = (type: string) => {
  const parts = type.split('_')
  if (type.includes('_') && parts.length > 2) {
    return parts.slice(0, parts.length - 2).join('_')
  } else {
    return type
  }
}

export const getNextQuestionId = (assessmentResponse: Array<IResponse>, nextQuestionId: string) =>
  assessmentResponse.find((item: any) => item.id === nextQuestionId)

export const redirectToPlayBookCard = (assessmentType: string) => {
  for (const key of Object.keys(PLAYBOOK_ASSESSMENTS)) {
    if (assessmentType.includes(PLAYBOOK_ASSESSMENTS[key])) {
      return PlayBookCategoryParam[key]
    }
  }

  return 'dashboard'
}

export const findQuestionsToShowInReview = (assessmentResponse: Array<IResponse>) => {
  const response: Array<IResponse> = []
  let nextQuestionId
  const firstQuestion = assessmentResponse?.find((question) => question?.previous?.length === 0)
  if (firstQuestion) {
    nextQuestionId = firstQuestion.next
  }
  while (nextQuestionId) {
    const nextQuestion = getNextQuestionId(assessmentResponse, nextQuestionId)

    if (nextQuestion) {
      response.push(nextQuestion)
      nextQuestionId = nextQuestion.next
    } else {
      break
    }
  }

  return response.filter((item) => item.showInReview)
}
export const getOptions = (assessmentOptions) => {
  let options: IOptions = {}
  assessmentOptions?.forEach((opt) => {
    if (!options[opt?.type]) options[opt?.type] = []
    options[opt?.type]?.push({
      label: opt.name,
      value: opt.id,
      industry: opt?.dependencies,
      toolTipText: opt?.toolTipText,
    })
  })
  return options
}
export const getChatRecipientData = (members: ChannelMembership, idToSkip: string) => {
  const recipientRoles: Array<string> = []
  const recipientNames: Array<string> = []
  const recipientIds: Array<string> = []

  for (const memberId in members) {
    if (memberId !== idToSkip) {
      const user = members[memberId].user
      if (user) {
        if (Array.isArray(user.glRoles)) {
          recipientRoles.push(...user.glRoles)
        }
        if (user.firstName && user.lastName) {
          recipientNames.push(user.firstName + ' ' + user.lastName)
        }
        if (user.id) {
          recipientIds.push(user.id)
        }
      }
    }
  }

  return {
    recipientRoles,
    recipientNames,
    recipientIds,
  }
}

export const calculateYearMonths = (inputMonth: string, inputYear: string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  if (!inputMonth || !inputYear) {
    return ''
  } else {
    const inputMonthNumeric = monthNames.indexOf(inputMonth) + 1

    const currentDate = new Date()
    const inputDate = new Date(Number(inputYear), inputMonthNumeric - 1, 1)

    const monthDifference =
      (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      inputDate.getMonth()
    const years = Math.floor(monthDifference / 12)
    const remainingMonths = monthDifference % 12

    let result = ''
    if (monthDifference < 12) {
      result = 'Less than 1 year'
    } else {
      if (years > 0) {
        result += years === 1 ? '1 Year' : `${years} Years`
      }

      if (remainingMonths > 0) {
        result += result.length > 0 ? ' ' : ''
        result += remainingMonths === 1 ? '1 Month' : `${remainingMonths} Months`
      }
    }

    return result
  }
}

export const smsSubscriptionAmpliLog = (user: IUserSummary, status: string) => {
  ampli.smsSubscriptionStatus({
    userName: `${user.firstName} ${user.lastName}`,
    mobileContactNumber: user.mobileContactNumber,
    status,
  })
}
export const isFunctionEmpty = (func: (() => void | {}) | undefined) => {
  if (!func) {
    return true
  } else {
    return false
  }
}
interface ISignedUrl {
  id: string
}

export const getFileData = (
  data: string[],
  files: CustomFile[],
  signedUrl: ISignedUrl[],
  tenantId: string,
  userId: string,
  isMultiple?: boolean
) => {
  let imgUrl = data.map((dat) => dat.split('/uploads/'))
  let url = imgUrl.map((url) =>
    !url[1] ? '' : 'https://' + process.env.REACT_APP_MEDIA_BUCKET + '/uploads/' + url[1]
  )

  const fileData = files?.map((file, index) => ({
    name: file.name,
    userId,
    url: url[index],
    id: signedUrl[index].id,
    type: file.type,
    size: file.size.toString(),
    tenantId,
  }))
  return { fileData: isMultiple ? fileData : fileData[0] }
}

export const yearOptions = () => {
  const currentYear = new Date().getFullYear()
  const years: IOption[] = []
  let startYear = 1899
  for (let i = startYear; i < currentYear; i++) {
    startYear++
    years.push({ label: startYear.toString(), value: startYear.toString() })
  }
  return years.reverse()
}

export const passwordErrors = {
  rulesNotMet: 'Password rules not met',
  passwordNotComplete: 'Password not complete',
  passwordNotMatch: 'Passwords do not match',
}
export const isFutureDate = (inputMonth: string, inputYear: string) => {
  const currentDate = new Date()
  const inputDate = new Date(`${inputMonth} ${inputYear}`)

  if (inputDate > currentDate) {
    return { message: 'Registration must not be in the future.' }
  } else {
    return null
  }
}
export const isMultiFieldDateInFuture = (multiFieldValue, optionType) => {
  const monthValue = multiFieldValue.find((obj) => obj.label === LABEL.MONTH)?.value
  const yearValue = multiFieldValue.find((obj) => obj.label === LABEL.YEAR)?.value

  return optionType === 'multi-field' && !isEmpty(isFutureDate(monthValue, yearValue))
}

export const getActiveStepAndNavigation = (
  id,
  playStage,
  playData,
  isPlayInReview,
  disableButton
) => {
  let activeTabIndex = 0
  let activeStep = ''
  let link = ''
  let activeStepIndex = 0
  let isFound = false
  if (supportedPlayIds.includes(id) ? !isPlayInReview : !disableButton) {
    for (let i = 0; i < playStage.length; i++) {
      if (playStage[i] && playStage[i].steps.length > 0) {
        for (let j = 0; j < playStage[i].steps.length; j++) {
          activeTabIndex = i
          activeStep = playData.playTabs[i].tabName.toLowerCase()
          link = getLink(id, playData.playTabs[i].tabName.toLowerCase())

          activeStepIndex = j
          if (!playStage[i].steps[j].isCompleted) {
            isFound = true
            break
          }
        }
        if (isFound) break
      }
    }
  } else {
    activeStep = playData.playTabs[0].tabName.toLowerCase()
    link = getLink(id, playData.playTabs[0].tabName.toLowerCase())
  }

  return { activeTabIndex, activeStep, link, activeStepIndex }
}
export function getSlotMoment(timeString: string, dateString: string) {
  const dateTimeString = `${dateString} ${timeString}`
  const formattedDate = moment(dateTimeString, 'YYYY-MM-DD h:mm A')
  return formattedDate
}

export const updateToolConnectionState = (
  currentConnectors: Tool[],
  action: { payload: { toolName: string; isConnected: boolean } }
) => {
  return currentConnectors.map((toolConnection) => {
    if (toolConnection.toolName === action.payload.toolName) {
      return { ...toolConnection, isConnected: action.payload.isConnected }
    }
    return toolConnection
  })
}
export const showChevronLeftIcon = (activeStep, activeQuestion) => {
  return (
    activeStep &&
    activeStep !== ACTIVE_STEP.WELCOME_OWNER &&
    !(activeStep === ACTIVE_STEP.QUESTIONS && isEmpty(activeQuestion))
  )
}

export function getReturnToPath() {
  const queryString = window.location.search.slice(1)
  const queryParams = parse(queryString.slice(0))
  return queryParams
}
export const checkPlayButtonDisabled = (playStage: IPlayStage[]) => {
  let isDisableButton = false
  playStage.forEach((step) => {
    isDisableButton = step.steps.every((st) => st.isCompleted)
  })

  return isDisableButton
}
