import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { GET_USER_FILES, SAVE_USER_FILE, UPLOAD_FILE } from 'store/types'
import CommonApi from 'api/common'
import { getUserFilesAction, saveUserFileAction, uploadFileAction } from 'store/actions/common'
import { get } from 'lodash'
import { getFileData } from 'utils/helper'
import { CustomFile } from 'types'

export function* uploadUserFile(files: CustomFile[], isMultiple?: boolean) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)

    const res = yield all(files.map((file) => call(CommonApi.getSignedUrl, file.type, file.name)))
    const signedUrl = res.map((response) => JSON.parse(response.getSignedUrl.data))

    const data = yield all(
      files.map((file, index) =>
        call(CommonApi.uploadFile, signedUrl[index].signedUrl, file, file.type)
      )
    )
    const clientId = yield select((state) => state.clients?.client?.profile?.id)
    const userId = yield select((state) => state.user?.user?.id)
    const id = clientId ? clientId : userId
    return getFileData(data, files, signedUrl, tenantId, id, isMultiple)
  } catch (error) {
    return error
  }
}

function* uploadFile(action) {
  try {
    const { fileData } = yield uploadUserFile(action.payload?.files, action.payload?.isMultiple)

    if (action?.payload?.saveFile) {
      yield call(CommonApi.saveUserFile, {
        sendNotification: false,
        files: action.payload?.isMultiple ? fileData : [fileData],
      })
    }
    yield put(
      uploadFileAction.FULLFILLED({
        fileData,
      })
    )
  } catch (error: any) {
    console.log('errors : ', error.message)
    yield put(uploadFileAction.FULLFILLED({ uploadFileErrors: error.message }))
  }
}
function* getUserFiles(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)

    const res = yield call(CommonApi.getUserFiles, action.payload.userId, tenantId)
    const data = JSON.parse(get(res, 'getUserFiles.data', ''))

    yield put(getUserFilesAction.FULLFILLED(data))
  } catch (error) {
    console.log('get user files error : ', error)
  }
}
function* saveUserFile(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    if (action?.payload?.generationNotification) {
      const filesData = yield select((state) => state.common?.uploadedFile)
      yield call(CommonApi.saveUserFile, { sendNotification: true, files: filesData })
      const payload = {
        userId: action.payload.userId,
      }
      yield call(getUserFiles, { payload })
    } else {
      const payload = { ...action.payload, tenantId }
      yield call(CommonApi.saveUserFile, { files: payload, sendNotification: false })
    }
    yield put(saveUserFileAction.FULLFILLED(action.payload))
  } catch (error) {
    console.log('Could not save file:', error)
    yield put(saveUserFileAction.REJECTED())
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherCommon() {
  yield takeLatest(UPLOAD_FILE.STARTED, uploadFile)
  yield takeLatest(SAVE_USER_FILE.STARTED, saveUserFile)
  yield takeLatest(GET_USER_FILES.STARTED, getUserFiles)
}
