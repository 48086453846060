import React, { FC, useEffect, useMemo, useState } from 'react'

import { DataReportsTabs } from 'config'
import { useParams } from 'react-router-dom'

import ButtonTabs from 'components/Common/ButtonTabs'
import GrowingContainer from 'components/Common/GrowingContainer'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { DataContainer, ReportsContainer } from 'pages/Common/container'
import { getRoleFromPath, navigateTo } from 'utils/helper'
import { ampli } from 'ampli'
import { GetAttestationSummaryInputWithoutTenant } from 'store/actions/attestationSummary'

interface IDataReportsProps {
  userId: string
  getAttestationSummaryAction: (payload: GetAttestationSummaryInputWithoutTenant) => void
}

const DataReports: FC<IDataReportsProps> = ({ userId, getAttestationSummaryAction }) => {
  let { subTab } = useParams()

  const dataReportsEnabled = useFeatureFlag(userId, 'release-attestations-rollout')

  useEffect(() => {
    if (dataReportsEnabled) {
      const today = new Date()
      getAttestationSummaryAction({
        timePeriod: {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate(),
        },
        advisorId: userId,
        includeAggregationDetails: true,
      })
    }
    // eslint-disable-next-line
  }, [dataReportsEnabled])

  const [selectedTab, setSelectedTab] = useState(DataReportsTabs[0])

  const userRole = getRoleFromPath()
  useEffect(() => {
    let foundTab = DataReportsTabs.find((tab) => tab.link === subTab)
    if (foundTab) {
      ampli.subtabViewed({ subTabName: foundTab.label })
      setSelectedTab(foundTab)
    } else {
      navigateTo(userRole, '')
    }
  }, [subTab, userRole])

  const onHandleTabChange = (value) => {
    setSelectedTab(value)
    let foundTab = DataReportsTabs.find((tab) => tab.label === value.label)
    if (foundTab) {
      navigateTo(userRole, `data-reports/${foundTab?.link}`)
    } else {
      navigateTo(userRole, `data-reports/${value?.label}`)
    }
  }

  const firstTabSelected = useMemo(() => {
    return selectedTab === DataReportsTabs[0]
  }, [selectedTab])

  return (
    <>
      <GrowingContainer id="data-and-reports">
        <div className={` xs:p-2 sm:p-0 w-full h-full`}>
          <div id="tab-container" className="w-full flex">
            <ButtonTabs
              options={DataReportsTabs}
              setSelectedTab={onHandleTabChange}
              selectedTab={selectedTab}
            />
          </div>
          <div className="w-full relative z-[5]">
            <div
              className={`bg-white grid-cols-12 p-4 sm:p-8 rounded-2xl mb-8 gap-12 shadow-lg mdl:pb-6 lg:pb-3 xl:pb-10 ${
                firstTabSelected && 'rounded-tl-none'
              }`}
            >
              {selectedTab.link === 'data' && <DataContainer />}
              {selectedTab.link === 'reports' && (
                <ReportsContainer reportsEnabled={dataReportsEnabled} />
              )}
            </div>
          </div>
        </div>
      </GrowingContainer>
    </>
  )
}

export default DataReports
