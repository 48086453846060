import { call, put, takeLatest, select } from 'redux-saga/effects'
import history from 'utils/history'
import hookForms from 'utils/hookForms'
import { ampli } from 'ampli'

import UserApi from 'api/user'
import CalendarApi from 'api/calendar'

import {
  PLAY_INTEGRATION_QUERY_PARAMS,
  integrationLogActions,
  defaultDuration,
  defaultValues,
  formKeys,
  FORM,
  MEETING_STEPPER,
  ADVISOR_ONBOARDING,
  ROLES,
} from 'config'

import { pickBy, get, isEmpty } from 'lodash'
import { setFormData } from 'store/actions/form'

import {
  CANCEL_USER_CALENDAR_EVENT,
  CANCEL_OUTLOOK_MEETING,
  DELETE_GOOGLE_CALENDAR_EVENT,
  GET_BO_ADVISOR,
  GET_USER_CONNECTORS,
  GET_USER_CALENDAR_EVENTS,
  GET_OAUTH_URL,
  GET_CALENDLY_USER,
  GET_USER_MEETING,
  GET_MEETING_TYPES,
  GET_USER_AVAILABILITY,
  SET_USER_AVAILABILITY,
  SET_USER_MEETING,
  GET_USER_AVAILABILITY_HOURS,
  HANDLE_CYCLR_OAUTH,
  SUBSCRIBE_CALENDLY_WEBHOOK,
  SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK,
  UPDATE_CALENDAR_MEETING,
  UPDATE_GOOGLE_CALENDAR_EVENT,
  UPDATE_USER_AVAILABILITY_HOURS,
  GET_ADVISOR_PROFILE,
  GET_USER_MEETING_BY_EVENT_ID,
} from 'store/types'

import {
  cancelUserCalendarEventAction,
  getBoAdvisorAction,
  getCalendlyUserAction,
  getOAuthUrlAction,
  getMeetingTypesAction,
  getUserMeetingAction,
  getUserAvailabilityAction,
  getUserAvailabilityHoursAction,
  getUserConnectorsAction,
  getUserCalendarEventsAction,
  handleCyclrOAuthAction,
  updateCalendarMeetingAction,
  setUserAvailabilityAction,
  setUserMeetingAction,
  subscribeGoogleCalendarWebhookAction,
  subscribeCalendlyWebhookAction,
  updateUserAvailabilityHoursAction,
  getAdvisorProfileAction,
  getUserMeetingByEventIdAction,
  updateMeetingStepperAction,
  openMeetingConfirmationModalBoxAction,
} from 'store/actions/calendar'
import { getRoleFromPath, requestMeetingLogsFields } from 'utils/helper'
import { stringify } from 'querystring'
import { setFlashUrlParams } from 'utils/flashParameters'
import { Action, UpdatedSetUserMeetingInput } from 'types'
import { toolConnectionSuccessAction } from 'store/actions/clients'

const getUserEvents = (events) => {
  events = events?.map((event) => ({
    ...event,
    end: event?.end ? event.end : null,
    start: event?.start ? event.start : null,
  }))
  return events
}
function* getUserConnectors(action) {
  try {
    const getUserConnectorsRespone = yield call(CalendarApi.getUserConnectors, action.payload)

    yield put(
      getUserConnectorsAction.FULLFILLED(
        get(getUserConnectorsRespone, 'getUserConnectors.data', [])
      )
    )
  } catch (error) {
    yield put(getUserConnectorsAction.REJECTED(error))
    console.log('get user connectors error : ', error)
  }
}

function* getUserCalendarEvents(action) {
  try {
    let { calendarName, timeMax, timeMin } = action.payload || {}

    const tenantId = yield select((state) => state.user?.tenantId)
    const getUserCalendarEventsRes = yield call(
      CalendarApi.getUserCalendarEvents,
      calendarName,
      timeMax,
      timeMin,
      tenantId
    )

    const events = getUserEvents(get(getUserCalendarEventsRes, 'getUserCalendarEvents.data', []))
    yield put(getUserCalendarEventsAction.FULLFILLED(events))
  } catch (error) {
    yield put(getUserCalendarEventsAction.REJECTED(error))
    console.log('get user calendar events error:', error)
  }
}

function* getOAuthUrl(action) {
  try {
    const { calendar, targetOrigin, oAuthConnectionProperties, connectorName, authType } =
      action.payload
    const tenantId = yield select((state) => state.user?.tenantId)
    const targetedOrigin =
      targetOrigin === ADVISOR_ONBOARDING
        ? window.location.origin + `/advisor/${tenantId}/onboarding/calendar-link`
        : targetOrigin
    const getOAuthUrlRes = yield call(
      CalendarApi.getOAuthUrl,
      calendar ?? connectorName,
      oAuthConnectionProperties,
      authType
    )
    const baseUrl = get(getOAuthUrlRes, 'getOAuthUrl.data', '')

    // for oauth
    if (baseUrl) {
      const encodedParams = encodeURIComponent(
        stringify({ connectorName: calendar ?? connectorName })
      )
      const url = baseUrl.replace(
        'targetOrigin=https://www.google.com',
        `targetOrigin=${targetedOrigin}?flash=${encodedParams}`
      )
      yield put(getOAuthUrlAction.FULLFILLED({ url, calendar }))
    }

    // for other auth types
    if (!baseUrl && getOAuthUrlRes?.getOAuthUrl.success) {
      yield put(toolConnectionSuccessAction.STARTED({ connectorName: calendar ?? connectorName }))
    }
    if (!baseUrl && !getOAuthUrlRes?.getOAuthUrl.success)
      throw new Error(
        `Could not retrieve valid OAuth URL for ${
          calendar ?? connectorName
        }. Please contact GoTackle Support for help.`
      )
  } catch (error) {
    const { calendar } = action.payload
    yield put(getOAuthUrlAction.REJECTED({ error, calendar }))
    console.log('get OAuth URL error: ', error)
  }
}

function* getBoAdvisors(action) {
  try {
    const res = yield call(CalendarApi.getBoAdvisors, action.payload)
    yield put(getBoAdvisorAction.FULLFILLED(get(res, 'getBoAdvisors.data', [])))
  } catch (error) {
    yield put(getBoAdvisorAction.REJECTED(error))
  }
}

function* subscribeCalendlyWebhook(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }
    const res = yield call(CalendarApi.subscribeCalendlyWebhook, payload)
    yield put(
      subscribeCalendlyWebhookAction.FULLFILLED(get(res, 'subscribeCalendlyWebhook.data', []))
    )
  } catch (error) {
    yield put(subscribeCalendlyWebhookAction.REJECTED(error))
  }
}

function* subscribeGoogleCalendarWebhook() {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const res = yield call(CalendarApi.subscribeGoogleCalendarWebhook, tenantId)
    yield put(
      subscribeGoogleCalendarWebhookAction.FULLFILLED(
        get(res, 'subscribeGoogleCalendarWebhook.data', [])
      )
    )
  } catch (error) {
    yield put(subscribeGoogleCalendarWebhookAction.REJECTED(error))
  }
}

function* getCalendlyUser() {
  try {
    const getCalendlyUserRes = yield call(CalendarApi.getCalendlyUser)
    yield put(getCalendlyUserAction.FULLFILLED(get(getCalendlyUserRes, 'getCalendlyUser.data', {})))
  } catch (error) {
    yield put(getCalendlyUserAction.REJECTED(error))
    console.log('get Calendly User error : ', error)
  }
}

function* getUserMeeting(action) {
  try {
    const boAdvisors = yield select((state) => state.calendar?.boAdvisors)

    let advisor = boAdvisors?.find((obj) => obj.schedulingUrl === action?.payload?.trim())

    if (advisor) {
      let advisorId = advisor.id

      const record = yield call(CalendarApi.getUserMeeting, advisorId)

      let { isScheduled } = JSON.parse(record?.getUserMeeting?.data)

      yield put(getUserMeetingAction.FULLFILLED(isScheduled))
    }
  } catch (error) {
    yield put(getCalendlyUserAction.REJECTED(error))
    console.log('get User Meeting error : ', error)
  }
}

function* handleCyclrOAuth(action) {
  const { connectorName, connector } = action.payload
  const isPlay = action.payload?.isPlay
  try {
    yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: false }))
    const getUserConnectorsRespone = yield call(CalendarApi.getUserConnectors, undefined)
    const userConnectors = get(getUserConnectorsRespone, 'getUserConnectors.data', [])
    const isConnected = userConnectors?.find((con) => con.name === connector)?.authenticated
    const getOAuthUrlRes = yield call(CalendarApi.getOAuthUrl, connectorName)

    const baseUrl = get(getOAuthUrlRes, 'getOAuthUrl.data', '')

    // for oauth
    if (baseUrl) {
      const url = `${get(getOAuthUrlRes, 'getOAuthUrl.data', '').replace(
        'targetOrigin=https://www.google.com',
        `targetOrigin=${window.location.href}?success=true`
      )}`

      if (!isConnected && !isPlay) {
        yield put(handleCyclrOAuthAction.FULLFILLED({ url, isConnected }))
      } else {
        if (history.location.search.includes(PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS)) {
          const play = yield select((state) => state.play.play)
          const tenantId = yield select((state) => state.user?.tenantId)
          const sessionToken = localStorage.getItem('sessionToken')

          yield call(UserApi.logActivity, {
            action: integrationLogActions[connectorName],
            logStatus: '',
            accessToken: sessionToken,
            tenantId,
            showClientActivity: true,
            type: play.id,
          })

          var newLink = history.location.pathname.split('?')
          history.replace(newLink[0])
        }

        yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected }))
      }
    }

    // for other auth types
    if (!baseUrl && getOAuthUrlRes.success) {
      const play = yield select((state) => state.play.play)
      yield put(toolConnectionSuccessAction.STARTED({ connectorName, playId: play.id }))
    }
  } catch (error) {
    console.log('get error : ', error)
  }
}

function* updateCalendarMeeting(action) {
  const {
    timeMin,
    timeMax,
    calendarName,
    id,
    tenantId,
    description,
    availability,
    attendees,
    start,
    end,
  } = action.payload
  const payloadData = {
    id,
    tenantId,
    description,
    availability,
    attendees,
    start,
    end,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.updateUserCalendarEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('updating calendar error : ', error)
  }
}

function* deleteGoogleCalendarEvent(action) {
  const { timeMin, timeMax, calendarName, tenantId, eventId, id } = action.payload

  const payloadData = {
    eventId: eventId,
    tenantId: tenantId,
    id: id,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.deleteGoogleCalendarEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('updating calendar error : ', error)
  }
}

function* updateGoogleCalendarEvent(action) {
  const {
    timeMin,
    timeMax,
    calendarName,
    id,
    tenantId,
    description,
    attendees,
    start,
    end,
    eventId,
  } = action.payload
  const payloadData = {
    eventId: eventId,
    tenantId,
    id: id,
    description,
    attendees,
    start,
    end,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.updateGoogleCalendarEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('updating calendar error : ', error)
  }
}
function* cancelOutlookEvent(action) {
  const { timeMin, timeMax, calendarName, eventId, tenantId } = action.payload
  const payloadData = {
    eventId: eventId,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.cancelOutlookEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('cancel outlook event error : ', error)
  }
}

function* getMeetingTypes(action) {
  try {
    const response = yield call(CalendarApi.getMeetingTypes, action.payload)
    yield put(getMeetingTypesAction.FULLFILLED(response.getMeetingTypes.data))
  } catch (error) {
    yield put(getMeetingTypesAction.REJECTED(error))
    console.log('Error getting meeting types: ', error)
  }
}

function* setUserAvailability(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }
    const response = yield call(CalendarApi.setUserAvailability, payload)
    yield put(setUserAvailabilityAction.FULLFILLED(response.setUserAvailability.data))
  } catch (error) {
    yield put(setUserAvailabilityAction.REJECTED(error))
    console.log('Error while setting availability hours : ', error)
  }
}

function* getUserAvailability(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const response = yield call(CalendarApi.getUserAvailability, { ...action.payload, tenantId })
    if (!response.getUserAvailability.data) {
      yield put(getUserAvailabilityAction.REJECTED('Error while getting availability hours'))
      return
    }

    yield put(getUserAvailabilityAction.FULLFILLED(response.getUserAvailability.data))
  } catch (error) {
    yield put(getUserAvailabilityAction.REJECTED(error))
    console.log('Error while getting availability hours : ', error)
  }
}

function* setUserMeeting(action: Action<string, UpdatedSetUserMeetingInput>) {
  const {
    title,
    organizerName,
    attendees,
    participantName,
    businessName = '',
    duration,
    organizationName,
    startTime,
    endTime,
    videoLinkDescription,
    description,
  } = action.payload
  const role = getRoleFromPath()

  delete action.payload.businessName
  delete action.payload.duration
  delete action.payload.organizationName

  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const response = yield call(CalendarApi.setUserMeeting, { ...action.payload, tenantId })
    if (!response?.setUserMeeting) {
      yield put(
        updateMeetingStepperAction({
          actionType: 'increment',
          error: 'Unable to create meeting',
          stepName: MEETING_STEPPER.MEETING_CONFIRMATION,
        })
      )
      yield put(setUserMeetingAction.REJECTED('unable to create meeting'))
      return
    }

    ampli.meetingBooked({
      initiatorRole: role,
      meetingTitle: title,
      originatorName: organizerName || '',
      guests: attendees,
      duration: duration!,
      endTime,
      startTime,
      videoLinkDescription: videoLinkDescription || '',
      description: description || '',
      ...requestMeetingLogsFields(participantName || '', businessName, organizationName || ''),
    })

    yield put(setUserMeetingAction.FULLFILLED(response.setUserMeeting))
    if (role === ROLES.BUSINESS_OWNER) {
      yield put(
        updateMeetingStepperAction({
          actionType: 'increment',
          stepName: MEETING_STEPPER.MEETING_CONFIRMATION,
        })
      )
    } else {
      yield put(
        updateMeetingStepperAction({ actionType: 'reset', stepName: MEETING_STEPPER.ADVISOR })
      )
      const flashParams = {
        meetingCreationStatus: 'success',
        toastVisibilityTimeout: '5000',
        toastMessage: 'Meeting created!',
        toastVariant: 'success',
      }
      setFlashUrlParams(flashParams)
    }
  } catch (error: any) {
    if (role === ROLES.BUSINESS_OWNER) {
      yield put(
        updateMeetingStepperAction({
          actionType: 'increment',
          stepName: MEETING_STEPPER.MEETING_CONFIRMATION,
        })
      )
      yield put(setUserMeetingAction.REJECTED(error.message))
    } else {
      yield put(
        updateMeetingStepperAction({ actionType: 'reset', stepName: MEETING_STEPPER.ADVISOR })
      )
      yield put(setUserMeetingAction.REJECTED(error.message))
    }
    console.log('Error while setting user meeting : ', error)
  }
}

function* cancelUserCalendarEvent(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const response = yield call(CalendarApi.cancelUserCalendarEvent, {
      ...action.payload.deleteEventPayload,
      tenantId,
    })
    if (!response?.cancelUserCalendarEvent?.data) {
      yield put(
        cancelUserCalendarEventAction.REJECTED({ error: { message: 'unable to cancel meeting' } })
      )
      yield put(
        openMeetingConfirmationModalBoxAction({
          error: 'Unable to cancel meeting',
          stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
          isMeetingDeletion: true,
        })
      )
    }

    let { timeMax, timeMin, calendarName } = action.payload.getEventPayload

    const getUserCalendarEventsRes = yield call(
      CalendarApi.getUserCalendarEvents,
      calendarName,
      timeMax,
      timeMin,
      tenantId
    )

    const events = getUserEvents(get(getUserCalendarEventsRes, 'getUserCalendarEvents.data', []))
    yield put(getUserCalendarEventsAction.FULLFILLED(events))
    yield put(cancelUserCalendarEventAction.FULLFILLED(events))
    yield put(
      openMeetingConfirmationModalBoxAction({
        error: '',
        stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
        isMeetingDeletion: true,
      })
    )
  } catch (error: any) {
    yield put(cancelUserCalendarEventAction.REJECTED(error))
    yield put(
      openMeetingConfirmationModalBoxAction({
        error: error?.message,
        stepType: MEETING_STEPPER.MEETING_CONFIRMATION,
        isMeetingDeletion: true,
      })
    )
    console.log('error: ', error)
  }
}
function* getUserAvailabilityHours(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    yield put(getMeetingTypesAction.STARTED({ tenantId }))
    let res = yield call(CalendarApi.getUserAvailabilityHours)
    let data
    if (res?.getUserAvailabilityHours?.data) {
      let availabilityData = res.getUserAvailabilityHours.data.availabilityHours
      let timeSlots = availabilityData.timeSlots.map((slot) => ({
        ...slot,
        checked: slot.slots.length > 0 ? true : false,
      }))
      data = {
        duration: availabilityData.duration,
        timeSlots,
      }
      if (action.payload.setForms) {
        const slotsData = pickBy(data, (value, key) =>
          formKeys[FORM.USER_AVAILABILITY].includes(key)
        )
        yield put(setFormData({ form: [FORM.USER_AVAILABILITY], data: slotsData }))
        action.payload.setValue('timeSlots', [...slotsData.timeSlots])
      }
    } else {
      if (action.payload.setForms) {
        const calendarForm = hookForms.getForm(action.payload.setForms)
        // const slotsData = pickBy(data, (value, key) =>
        //   formKeys[FORM.USER_AVAILABILITY].includes(key)
        // )
        // yield put(setFormData({ form: [FORM.USER_AVAILABILITY], data: slotsData }))
        calendarForm.reset({
          timeSlots: defaultValues.timeSlots,
          duration: defaultDuration[0].duration.toString(),
        })
        // action.payload.setValue()
        // action.payload.setValue()
      }
    }
    yield put(getUserAvailabilityHoursAction.FULLFILLED(data))
  } catch (error) {
    yield put(getUserAvailabilityHoursAction.REJECTED(error))
    console.log('error: ', error)
  }
}
function* updateUserAvailabilityHours(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }
    let res = yield call(CalendarApi.updateUserAvailability, payload)
    yield put(updateUserAvailabilityHoursAction.FULLFILLED(res?.updateUserAvailability?.data))
  } catch (error) {
    yield put(updateUserAvailabilityHoursAction.REJECTED(error))
    console.log('error: ', error)
  }
}
function* getAdvisorProfile(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const res = yield call(CalendarApi.getAdvisorProfile, { ...action.payload, tenantId })
    yield put(getAdvisorProfileAction.FULLFILLED(res?.getAdvisorProfile))
  } catch (error) {
    yield put(getAdvisorProfileAction.REJECTED())
  }
}
function* getUserMeetingEventById(action) {
  try {
    const userRole = getRoleFromPath()
    const tenantId = yield select((state) => state.user.tenantId)
    let res = yield call(CalendarApi.getUserMeetingById, { ...action.payload, tenantId })
    const data = res?.getUserMeetingById?.data

    if (isEmpty(data?.id)) {
      history.replace(`/${userRole}/${tenantId}/calendar`)
    }
    yield put(getUserMeetingByEventIdAction.FULLFILLED(data))
  } catch (error) {
    console.log('error: ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherCalendar() {
  yield takeLatest(GET_OAUTH_URL.STARTED, getOAuthUrl)
  yield takeLatest(GET_CALENDLY_USER.STARTED, getCalendlyUser)
  yield takeLatest(GET_USER_CONNECTORS.STARTED, getUserConnectors)
  yield takeLatest(GET_USER_CALENDAR_EVENTS.STARTED, getUserCalendarEvents)
  yield takeLatest(GET_BO_ADVISOR.STARTED, getBoAdvisors)
  yield takeLatest(SUBSCRIBE_CALENDLY_WEBHOOK.STARTED, subscribeCalendlyWebhook)
  yield takeLatest(SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.STARTED, subscribeGoogleCalendarWebhook)
  yield takeLatest(GET_USER_MEETING.STARTED, getUserMeeting)
  yield takeLatest(HANDLE_CYCLR_OAUTH.STARTED, handleCyclrOAuth)
  yield takeLatest(UPDATE_CALENDAR_MEETING.STARTED, updateCalendarMeeting)
  yield takeLatest(DELETE_GOOGLE_CALENDAR_EVENT.STARTED, deleteGoogleCalendarEvent)
  yield takeLatest(UPDATE_GOOGLE_CALENDAR_EVENT.STARTED, updateGoogleCalendarEvent)
  yield takeLatest(CANCEL_OUTLOOK_MEETING.STARTED, cancelOutlookEvent)
  yield takeLatest(GET_MEETING_TYPES.STARTED, getMeetingTypes)
  yield takeLatest(SET_USER_AVAILABILITY.STARTED, setUserAvailability)
  yield takeLatest(GET_USER_AVAILABILITY.STARTED, getUserAvailability)
  yield takeLatest(SET_USER_MEETING.STARTED, setUserMeeting)
  yield takeLatest(CANCEL_USER_CALENDAR_EVENT.STARTED, cancelUserCalendarEvent)
  yield takeLatest(GET_USER_AVAILABILITY_HOURS.STARTED, getUserAvailabilityHours)
  yield takeLatest(UPDATE_USER_AVAILABILITY_HOURS.STARTED, updateUserAvailabilityHours)
  yield takeLatest(GET_ADVISOR_PROFILE.STARTED, getAdvisorProfile)
  yield takeLatest(GET_USER_MEETING_BY_EVENT_ID.STARTED, getUserMeetingEventById)
}
