import React, { memo } from 'react'

import _ from 'lodash'
import { AppFC, FontSize } from 'types'
import uuid from 'react-uuid'
import { isMobileOnly } from 'mobile-device-detect'

import ErrorMessage from 'components/Common/ErrorMessage'
import Select, { components, MultiValueRemoveProps } from 'react-select'
import { CrossIcon, DropDownIcon, SelectFieldCancelIcon } from 'components/Common/SvgIcons'
import { PlacesType } from 'react-tooltip'
import ToolTipWithButton from 'components/Common/ToolTipWithButton'

export interface IInputFieldProps {
  name?: string
  label?: string
  placeholder?: string
  error?: { message: string }
  options: Array<{ label: any; value: any }>
  hidden?: boolean
  onChange?: any
  value?: any
  errors?: any
  isMulti?: boolean
  classes?: any
  applyStyle?: any
  onInputChange?: any
  isSearchable?: boolean
  components?: any
  closeMenuOnSelect?: boolean
  blurInputOnSelect?: boolean
  filterOption?: any
  menuPortalTarget?: boolean
  disable?: boolean
  menuIsOpen?: boolean
  isDropDownMenu?: boolean
  customStyle?: { control?: any; valueContainer?: any; container?: any }
  id?: string
  labelFontSize?: FontSize
  toolTipText?: string
  toolTipWidth?: string
  toolTipPlace?: PlacesType
  toolTipClass?: string
  toolTipOpacity?: number
  toolTipBackgroundColor?: string
}

const MultiValueRemove: AppFC<MultiValueRemoveProps<any>> = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      {isMobileOnly ? (
        <CrossIcon className="h-[14px] w-[14px]" />
      ) : (
        <SelectFieldCancelIcon className="fill-black-light" />
      )}
    </components.MultiValueRemove>
  )
}
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon
        className={`${
          props.isDisabled
            ? !props.hasValue
              ? 'fill-primary-disabledText'
              : 'fill-grey-darkest1x'
            : 'fill-primary'
        } hover:fill-primary-brand focus:fill-primary-active active:fill-primary-active h-5`}
      />
    </components.DropdownIndicator>
  )
}

const SelectField: AppFC<IInputFieldProps> = ({
  name,
  label,
  placeholder,
  error,
  options,
  hidden,
  errors,
  onChange,
  isMulti,
  classes,
  value,
  components,
  applyStyle,
  closeMenuOnSelect,
  blurInputOnSelect,
  menuPortalTarget,
  disable,
  isDropDownMenu,
  customStyle = {},
  id = uuid(),
  labelFontSize,
  toolTipText,
  toolTipWidth,
  toolTipPlace,
  toolTipClass,
  toolTipOpacity,
  toolTipBackgroundColor,
  ...rest
}) => {
  const selectHandleChange = (option) => {
    isMulti ? onChange(option.map((options) => options.value)) : onChange(option.value)
  }

  const getSelected = () => {
    if (isMulti) {
      const selectedValue: any = []
      for (let i = 0; i < value?.length; i++) {
        const option = _.find(options, { value: value[i] })
        selectedValue.push(option)
      }
      return selectedValue
    } else {
      return _.find(options, { value: value }) || null
    }
  }
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        border: 'none',
        backgroundColor: '#F4F4F5',
        borderRadius: '10px',
        padding: '5px 10px !important',
        ...(customStyle?.control ?? {}),
      }
    },
    container: (provided, state) => {
      return {
        ...provided,
        ...(customStyle?.container ?? {}),
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: disable ? '#B0B0B0' : '#808080',
        fontWeight: 'normal',
      }
    },
    input: (provided, state) => {
      return {
        ...provided,
        fontSize: '16px', // Default 16px font size for mobile
        '@media (min-width: 500px)': {
          fontSize: '20px',
        },
      }
    },
    valueContainer: (provided, state) => {
      return {
        ...provided,
        fontSize: '16px',
        ...(customStyle?.valueContainer ?? {}),
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: '16px',
        color: '#3F3F46',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? '#DCF0FE' : state.isFocused ? '#F4F4F5' : 'white',
      }
    },
    menuPortal: (base, state) => {
      return {
        ...base,
        zIndex: 9999,
      }
    },
    menu: (base, state) => {
      return {
        ...base,
        minWidth: isDropDownMenu ? '300px' : 'auto',
        border: isDropDownMenu ? '1px solid #D4D4D8' : 'none',
        boxShadow: isDropDownMenu
          ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
          : '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
      }
    },

    menuList: (base, state) => {
      return {
        ...base,
        borderRadius: isDropDownMenu ? '4px' : 'auto',
      }
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: disable ? '#808080 !important' : '#18181b !important',
        fontSize: '20px !important',
        fontWeight: '700',
        lineHeight: '20px !important',
      }
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#DCF0FE',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '8px',
        paddingRight: '8px',
        color: disable ? '#808080 !important' : '#18181b !important',
        borderRadius: '53px',
        fontFamily: 'Futura Std',
        fontSize: '19px !important',
        fontWeight: '700',
        lineHeight: '20px !important',
      }
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      ':hover': {
        color: '#000',
      },
    }),
  }

  return (
    <>
      <div className={`${hidden ? 'hidden' : ''} ${classes}`}>
        <ToolTipWithButton
          label={label}
          toolTipText={toolTipText}
          toolTipPlace={toolTipPlace}
          toolTipWidth={toolTipWidth}
          labelFontSize={labelFontSize}
          toolTipClass={toolTipClass}
          toolTipOpacity={toolTipOpacity}
          toolTipBackgroundColor={toolTipBackgroundColor}
        />
        <div>
          <Select
            {...rest}
            menuPortalTarget={menuPortalTarget ? document.body : undefined}
            blurInputOnSelect={blurInputOnSelect}
            closeMenuOnSelect={closeMenuOnSelect}
            placeholder={`${placeholder || ''}`}
            onChange={selectHandleChange}
            value={getSelected()}
            styles={customStyles}
            isDisabled={disable}
            options={options}
            isMulti={isMulti}
            name={name}
            className="selectFieldData"
            classNamePrefix="select"
            id={id}
            menuPlacement="auto"
            components={
              components
                ? {
                    ...components,
                    MultiValueRemove,
                    IndicatorSeparator: () => null,
                  }
                : {
                    IndicatorSeparator: () => null,
                    MultiValueRemove,
                    DropdownIndicator,
                  }
            }
          />
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </>
  )
}

SelectField.defaultProps = {
  menuPortalTarget: true,
  isDropDownMenu: false,
}

export default memo(SelectField)
