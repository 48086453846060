import { GET_BSO_PROFILE } from 'store/types'
import { BusinessSupportOrganization, Scalars } from '__generated__/api-types-and-hooks'

type Term = {
  term: string
  replacement: string
}

export interface BSOProfile extends BusinessSupportOrganization {
  logoUrl: Scalars['String']
  terminology: Array<Term>
  licenseCount: number
  licensesUsed: number
}

export interface BSOState {
  bsoProfile: BSOProfile
}

const initialState: BSOState = {
  bsoProfile: {
    id: '',
    adminId: '',
    name: '',
    logoUrl: '',
    terminology: [],
    licenseCount: 0,
    licensesUsed: 0,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BSO_PROFILE.STARTED:
    case GET_BSO_PROFILE.RESET:
      return {
        ...initialState,
      }
    case GET_BSO_PROFILE.FULLFILLED:
      return {
        ...state,
        bsoProfile: {
          ...action.payload,
        },
      }
    default:
  }
  return state as BSOState
}

export default reducer
