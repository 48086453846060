import React, { useState, useEffect } from 'react'

import { CREATE_ADVISOR_ACCOUNT_STEPS, ROLES } from 'config'
import { scrollToTop, navigateTo } from 'utils/helper'

import {
  AccountCreatedContainer,
  CreateAccountContainer,
  UserAssignmentContainer,
} from 'pages/Admin/container'
import { StepperWrapper } from 'components/Common/StyledComponents/StepperWrapper'
import UserCreationContainer from 'components/Common/UserCreationContainer'
import ProfileForm from 'components/Common/ProfileForm'
import { ProgressbarContainer } from 'pages/Common/container'
import CloseButton from 'components/Common/CloseButton'
import AdminAccess from 'components/Admin/AdvisorStepper/AdminAccess'
import {
  AdvisorFormWrapper,
  ProfileFormWrapper,
  ProgressbarWrapper,
  UserStepperWrapper,
  FormHeadingTextWrapper,
} from 'components/Admin/AdvisorStepper/style'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { replaceTerm } from 'utils/helper'

export default function AdvisorStepper({
  steps,
  activeStep,
  getUsersAction,
  resetGetClientsAction,
  resetProgressbarAction,
  addAdvisorAccountAction,
  updateProgressbarAction,
  resetAccountErrorsAction,
  setAdvisorActiveStepAction,
  resetGetUserProfileAction,
  resetAdvisorAndBusinessFormsAction,
}) {
  const [formData, setFormData] = useState<any>()
  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)

  useEffect(() => {
    getUsersAction({ roles: ['BUSINESS_OWNER'] })
    resetGetUserProfileAction()
    resetGetClientsAction()
    // returned function will be called on component unmount
    return () => {
      resetAccountErrorsAction()
      resetProgressbarAction()
      setAdvisorActiveStepAction(CREATE_ADVISOR_ACCOUNT_STEPS.PROFILE_FORM)
      resetAdvisorAndBusinessFormsAction()
    }
    // eslint-disable-next-line
  }, [getUsersAction, resetAccountErrorsAction, resetProgressbarAction, setAdvisorActiveStepAction])

  const handleOnSubmits = (data) => {
    if (data) {
      setFormData({ ...formData, ...data })
    }
    if (activeStep === CREATE_ADVISOR_ACCOUNT_STEPS.ACCOUNT_CREATED) {
      navigateTo(ROLES.BSO_ADMIN, 'user-management/advisors')
    }

    if (activeStep === CREATE_ADVISOR_ACCOUNT_STEPS.CREATE_ACCOUNT) {
      addAdvisorAccountAction(formData)
      setFormData(null)
    }

    if (activeStep !== CREATE_ADVISOR_ACCOUNT_STEPS.CREATE_ACCOUNT) {
      const indexofActiveStep = steps.findIndex((step) => step?.title === activeStep)
      setAdvisorActiveStepAction(steps[indexofActiveStep + 1]?.title)
      updateProgressbarAction()
    }
    scrollToTop()
  }

  const getClass = (title) => {
    return activeStep === title ? 'block' : 'hidden'
  }

  const advisorTerm = replaceTerm('advisor', bsoProfile.terminology).toUpperCase()

  return (
    <>
      <StepperWrapper>
        <UserCreationContainer>
          <UserStepperWrapper>
            <ProgressbarWrapper>
              <ProgressbarContainer totalSteps={steps.length - 1} />
            </ProgressbarWrapper>
            <CloseButton onClick={() => navigateTo(ROLES.BSO_ADMIN, 'user-management/advisors')} />
          </UserStepperWrapper>
          <AdvisorFormWrapper>
            <FormHeadingTextWrapper> CREATE A NEW {advisorTerm} ACCOUNT</FormHeadingTextWrapper>
            <ProfileFormWrapper>
              <div
                className={`${getClass(
                  CREATE_ADVISOR_ACCOUNT_STEPS.PROFILE_FORM
                )} sm:min-w-[400px]`}
              >
                <ProfileForm onSubmit={handleOnSubmits} type="advisor" heading="Create a Profile" />
              </div>
              {getClass(CREATE_ADVISOR_ACCOUNT_STEPS.ASSIGN_A_CLIENT) === 'block' && (
                <div
                  className={`${getClass(
                    CREATE_ADVISOR_ACCOUNT_STEPS.ASSIGN_A_CLIENT
                  )} sm:min-w-[700px]`}
                >
                  <UserAssignmentContainer
                    handleOnSubmits={handleOnSubmits}
                    userAssignment={'client'}
                  />
                </div>
              )}
              <div
                className={`${getClass(
                  CREATE_ADVISOR_ACCOUNT_STEPS.ADMIN_ACCESS
                )} sm:max-w-[400px]`}
              >
                <AdminAccess handleOnSubmits={handleOnSubmits} />
              </div>
              <div
                className={`${getClass(
                  CREATE_ADVISOR_ACCOUNT_STEPS.CREATE_ACCOUNT
                )} sm:max-w-[400px]`}
              >
                <CreateAccountContainer
                  userEmail={formData?.email}
                  handleOnSubmits={handleOnSubmits}
                />
              </div>
              <div className={getClass(CREATE_ADVISOR_ACCOUNT_STEPS.ACCOUNT_CREATED)}>
                <AccountCreatedContainer handleOnSubmits={handleOnSubmits} accountName="advisor" />
              </div>
            </ProfileFormWrapper>
          </AdvisorFormWrapper>
        </UserCreationContainer>
      </StepperWrapper>
    </>
  )
}
