import { call, put, takeLatest, select } from 'redux-saga/effects'
import UserApi from 'api/user'

import {} from 'store/actions/user'
import { GET_BSO_PROFILE } from 'store/types'

import { getBSOProfileAction } from 'store/actions/bso'

function* getBSOProfile() {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const bsoRes = yield call(UserApi.getBSOProfile, tenantId)

    if (bsoRes) {
      yield put(getBSOProfileAction.FULLFILLED(bsoRes.getBusinessSupportOrganization))
    }
  } catch (error) {
    console.log('get bso profile error  : ', error)
    yield put(getBSOProfileAction.REJECTED())
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherBSO() {
  yield takeLatest(GET_BSO_PROFILE.STARTED, getBSOProfile)
}
